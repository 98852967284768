import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import CustomTablePDF from "./table";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingBottom: 150,
    paddingTop: 180
  },
  letterheader: {
    // height: "150px",
    // paddingHorizontal: 16,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 14,
    paddingHorizontal: 32,
  },
  header: {
    flexDirection: "row",
    paddingBottom: 48,
    fontSize: 10,
    fontFamily: "Times-Roman",
    paddingHorizontal: 32,
  },
  headerSectionLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  headerSectionRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  letterfooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const fields = [
  {
    title: "Sr. No.",
    custom: true,
    component: (item) => `${item.srNo}`,
    width: "10%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Particulars",
    custom: true,
    component: (item) => `${item.name}`,
    width: "50%",
  },
  {
    title: "Qty",
    custom: true,
    component: (item) => `${item.quantity}`,
    width: "7%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Test Pressure",
    custom: true,
    component: (item) => `${item.testPressure}`,
    width: "18%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Result",
    custom: true,
    component: (item) => `${item.result}`,
    width: "15%",
    style: {
      textAlign: "center",
    },
  },
  // {
  //     title: " Policy No",
  //     custom: true,
  //     component: (item) => `${item.policy_no}`,
  //     width: "35"
  // },
  // {
  //     title: "Policy Class",
  //     value: 'policy_class',
  //     width: "20"
  // },
  // {
  //     title: "Amount",
  //     custom: true,
  //     style: { textAlign: "right" },
  //     className: "text-right",
  //     component: (item) =>`Hello`,
  //     width: "15"
  // }
];

function getYearString() {
  const currentYear = new Date().getFullYear();
  const yearString = `${currentYear.toString().slice(-2)}-${(currentYear + 1)
    .toString()
    .slice(-2)}`;
  return yearString;
}
// Create Document Component
const TestingCertificate = ({
  letterheader,
  letterfooter,
  headingDate,
  customer,
  data,
  certificateNumber,
  extendedPeriod,
  invoiceNumber,
  invoiceDate,
  warrantyPeriod,
  validUpto,
  type,
}) => (
  <Page size="A4" style={styles.page}>
    {letterheader && (
      <View fixed style={styles.letterheader}>{<Image src={letterheader} />}</View>
    )}
    <View style={{ ...styles.title, paddingTop: letterheader ? 16 : 64 }}>
      <Text
        style={{
          ...styles.title,
          fontSize: 22,
          paddingBottom: 32,
          textDecoration: "underline",
        }}
      >
        TEST / WARRANTY CERTIFICATE
      </Text>
    </View>
    <View style={{ ...styles.header, paddingTop: 16, paddingBottom: 32 }}>
      <View style={styles.headerSectionRight}>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 5, fontSize: 11 }}
        >
          CERTIFICATE NO:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>
            FFSS/{getYearString()}/{certificateNumber}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.body}>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        This is to certify that the Following Fire Extinguishers have been{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{type}</Text> and installed
        by us. As per relevant ISI Specifications and Supplied on{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{headingDate}</Text> to the{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{customer.name}</Text>.
      </Text>
      <Text style={{ fontFamily: "Times-Roman", textAlign: "justify" }}>
        Our Invoice No{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{invoiceNumber}</Text> Dated{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{invoiceDate}</Text> is
        warranted for a period of{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{warrantyPeriod}</Text> from
        the date of supplied for any manufacturing defect.
      </Text>
    </View>
    <View
      style={{
        marginHorizontal: 32,
        marginVertical: 32,
      }}
    >
      <CustomTablePDF fields={fields} data={data} />
    </View>
    <View style={{...styles.body, height: 150}} wrap={false}>
      <Text
        style={{
          paddingBottom: 80,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        Further, We certify that the above material are made of best quality in
        workmanship free from all defects in material and workmanship, If any
        inconsistency in the performance of the material in that event we
        undertake free repair or replacement during the warranty period.
      </Text>
      <Text style={{ textAlign: "right", fontSize: 12}}>
        For Fire Fight Safety Solutions
      </Text>
    </View>
    {letterfooter && (
      <View fixed style={styles.letterfooter}>{<Image src={letterfooter} />}</View>
    )}
  </Page>
);

export default TestingCertificate;
