import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  Tag,
  message,
  Descriptions,
  Space,
  Modal,
  Form,
  Input,
  DatePicker,
} from "antd";
import APICall from "../../../services/apiservices";
import moment from "moment";
import downloadXLSX from "../../../employee/features/survey/export";
import downloadFireExtinguisherXLSX from "../../../employee/features/survey/fireExtExport";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
const { confirm } = Modal;
const { Item } = Form;

const PODetails = () => {
  const [visitsForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [poDetails, setPODetails] = useState([]);
  const { poId } = useParams();
  const [visitId, setVisitId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(undefined);
  const [employeeValue, setEmployeeValue] = useState(undefined);
  const navigate = useNavigate();

  const getPODetails = async () => {
    try {
      const response = await APICall(`/purchaseOrder/${poId}`, {}, 1);
      setPODetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch purchaseOrder details");
      setIsLoading(false);
    }
  };

  const handleEditVisit = async (formData) => {
    try {
      delete formData.assignedTo;
      const dataTosend = {
        assignedTo: formData.data.data,
        scheduledDate: formData.scheduledDate,
        description: formData.description,
      };
      const res = await APICall(`/visits/${visitId}`, dataTosend, 3);
      setIsModalOpen(false);
      setIsLoading(false);
      message.success("Visit updated successfully!");
      getPODetails();
    } catch (error) {
      message.error(error.response.data.message);
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPODetails();
  }, []);

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/purchaseOrder/${poId}`, {}, 4);
        message.success("Purchase Order deleted successfully!");
        navigate("/purchaseOrder");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="PO Details"
          loading={isLoading}
          extra={
            <Space>
              <Link to="/purchaseOrder">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Link to={`/purchaseOrder/manage/${poId}`}>
                <Button type="primary">Edit</Button>
              </Link>
              {/* <Link to={`/purchaseOrder/${poId}`}>
                <Button type="primary">Export</Button>
              </Link> */}
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions bordered layout="vertical" style={{ width: "100%" }}>
            <Descriptions.Item label="PO Number">
              {poDetails?.poNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Customer Name">
              {poDetails?.customer?.user?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Timeline">
              {poDetails?.timeline + " Months"}
            </Descriptions.Item>
            <Descriptions.Item label="PO Date">
              {dayjs(poDetails?.date).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {poDetails?.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Systems">
              {poDetails?.systems?.join(', ')}
            </Descriptions.Item>
            <Descriptions.Item label="Attachment">
              {<a href={poDetails?.file?.url}>Download</a>}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

export default PODetails;
