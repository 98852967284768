import React from "react";
import {
  Document,
  Font,
  pdf,
} from "@react-pdf/renderer";

Font.register({
  family: 'Tinos',
  fonts: [
    {
      src: '/fonts/Tinos-Regular.ttf',
      fontWeight: 400,
      fontStyle: 'normal'
    },
    {
      src: '/fonts/Tinos-Bold.ttf',
      fontWeight: 700,
      fontStyle: 'normal'
    },
    {
      src: '/fonts/Tinos-Italic.ttf',
      fontWeight: 400,
      fontStyle: 'italic'
    },
    {
      src: '/fonts/Tinos-BoldItalic.ttf',
      fontWeight: 700,
      fontStyle: 'italic'
    }
  ]
})

export const downloadPDF = async (documentJSX, fileName) => {
  const blob = await pdf(documentJSX).toBlob();
  //create blob
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.pdf`);
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const PDFDocument = ({ pages }) => (
  <Document>{pages.map((page) => page)}</Document>
);

export const handleDownloadPDF = async ({ pages, fileName }) => {
  const PdfDocumen = <PDFDocument pages={pages} />;

  await downloadPDF(PdfDocumen, fileName);
};
