import React, { useState } from "react";
import { Checkbox, Typography, Space, Tag, message, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Text } = Typography;

const SingleTask = ({ task, onStatusChange, handleDelete }) => {


  const [checked, setChecked] = useState( task.status === "Completed");
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const status = isChecked ? "Completed" : "Pending";
    setChecked(isChecked); 
    onStatusChange(task._id, status); 
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
        padding: "15px",
        border: "1px solid #ddd",   
        borderRadius: "8px",
        backgroundColor: checked ? "#e6ffed" : "#fff", 
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        transition: "background-color 0.3s ease",
      }}
    >
      <Space 
        style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <div>
            <Checkbox
                checked={checked}
                onChange={handleCheckboxChange}
                style={{
                transform: "scale(1.5)",
                marginRight: "1.5em",
                marginLeft: "1em"
                }}
            />
            <Space>
              <Text
              key={checked}
              delete={checked}
              style={{
                  fontSize: "16px",
                  paddingRight: "0.4em",
              }}
              >
              {task.title}
              </Text>
              <Link to={`manage/${task._id}`}>
                <EditOutlined style={{color: 'rgba(0, 0, 0, 0.5)'}} />
              </Link>
              <Link onClick={() => handleDelete(task._id)}>
                <DeleteOutlined style={{color: 'rgba(0, 0, 0, 0.5)'}} />
              </Link>
            </Space>
        </div>
        
        <Text style={{ color: "#595959" }}>
          <strong>Assigned to:</strong> {task.employeeName || "N/A"}
        </Text>
      </Space>
      
    </div>
  );
};

export default SingleTask;
