import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Form, Input, Button, Row, Col, Card, message, Select } from "antd";
import APICall from "../../../services/apiservices";
import qs from "qs";

const { Item } = Form;

const FormFields = ({ form, isEdit }) => (
  <Row gutter={16}>
    <Col span={24}>
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            name="clientName"
            label="Client Name"
            rules={[
              {
                required: true,
                message: "Please enter client name",
              },
            ]}
          >
            <Input placeholder="E.g. Reliance Industries Limited" />
          </Form.Item>
        </Col>


        <Col span={8}>
          <Item
            label="Service"
            name="service"
            rules={[
              {
                required: true,
                message: "Please selete service",
              },
            ]}
          >
            <Input placeholder="E.g. Services provided are..." />
          </Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="modeOfPayment"
            label="Select Mode of Payment"
            rules={[
              {
                required: true,
                message: "Please select the mode of payment",
              },
            ]}
          >
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="e.g. Cash"
              options={[
                {
                  label: "Cash",
                  value: "Cash",
                },
                {
                  label: "Credit Card",
                  value: "Credit Card",
                },
                {
                  label: "Bank Transfer",
                  value: "Bank Transfer",
                },
                {
                  label: "Other",
                  value: "Other",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: "Please add amount",
              },
            ]}
          >
            <Input placeholder="E.g. 16957" />
          </Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please select description",
              },
            ]}
          >
            <Input placeholder="E.g. This is a sell" />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  </Row>
);

const ManageCashLedger = ({ isEdit }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  let { type } = queryParams;
  if (!type) type = "Sell";

  const initializeForm = async () => {
    setIsLoading(true);
    try {
      const response = await APICall(`/cashLedger/${params.id}`, {}, 1);
      form.setFieldsValue({
        type: response.data.type,
        clientName: response.data.clientName,
        description: response.data.description,
        amount: response.data.amount,
        service: response.data.service,
        modeOfPayment: response.data.modeOfPayment,
      });
    } catch (error) {
      console.log(error);
      message.error('Failed getting data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      initializeForm();
    }
  }, [isEdit]);

  const submitForm = async (values) => {
    setIsLoading(true);
    try {
      const data = { ...values, type: type };
      const response = await APICall(`/cashLedger/${isEdit ? params.id : ''}`, data, isEdit ? 3 : 2);
      if (response.data) {
        message.success(`Cash Ledger has been ${isEdit ? 'updated' : 'created'} successfully.`);
        navigate(`/cashLedger?type=${type}`);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row gutter={10}>
      <Col span={24}>
        <Card title={params.id ? 'Edit Cash Ledger' : `Create ${type} Cash Ledger`}>
          <Form form={form} layout="vertical" onFinish={submitForm} initialValues={{ includeGST: true }}>
            <FormFields form={form} isEdit={isEdit} />
            <Col span={24}>
              <Button type="primary" htmlType="submit" tabIndex={8}>
                {isEdit ? "Save" : "Create"}
              </Button>
            </Col>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ManageCashLedger;
