import { Col, Form, Input, Row, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useEffect } from "react";

const BOQItem = ({ item, updateMegaRecordsData, showInternalRate }) => {
  const unit = item.unit;
  const [name, setName] = useState(item.name);
  const [make, setMake] = useState(item.make);
  const [quantity, setQuantity] = useState(item.quantity || 0);
  const [materialRate, setMaterialRate] = useState(item.materialRate || 0);
  const [labourRate, setLabourRate] = useState(item.labourRate || 0);
  const [materialTotal, setMaterialTotal] = useState(item.materialTotal || 0);
  const [labourTotal, setLabourTotal] = useState(item.labourTotal || 0);
  const [isSelected, setIsSelected] = useState(item.isSelected || false);
  const [originalName] = useState(item.name);
  const [originalMake] = useState(item.make);
  const [originalUnit] = useState(item.unit);
  const [originalMaterialRate] = useState(item.materialRate);
  const [originalLabourRate] = useState(item.labourRate);
  const [purchaseRate, setPurchaseRate] = useState(item.purchaseRate);
  const [labourInternalRate, setLabourInternalRate] = useState(item.labourInternalRate || 0);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsSelected(isChecked);
    updateMegaRecordsData({ ...item, isSelected: isChecked });
  };

  useEffect(() => {
    setMaterialTotal(quantity * materialRate);
    setLabourTotal(quantity * labourRate);
  }, [quantity, materialRate, labourRate]);

  useEffect(() => {
    const updatedItem = {
      ...item,
      name,
      make,
      unit,
      quantity,
      materialRate,
      labourRate,
      materialTotal,
      labourTotal,
      purchaseRate,
      labourInternalRate,
      isSelected,
      originalName,
      originalMake,
      originalUnit,
      originalMaterialRate,
      originalLabourRate,
    };
    updateMegaRecordsData(updatedItem);
  }, [name, make, unit, quantity, materialRate, labourRate, materialTotal, labourTotal, purchaseRate, labourInternalRate, isSelected]);

  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16} align="top">
          <Col>
            <Checkbox checked={isSelected} onChange={handleCheckboxChange} style={{ marginTop: "24px" }} />
          </Col>
          <Col span={5}>
            <Form.Item label="Name">
              <TextArea rows={1} value={name} onChange={(e) => setName(e.target.value)} disabled={!isSelected} />
            </Form.Item>
          </Col>

          {isSelected && (
            <>
              <Col span={2}>
                <Form.Item label="Make">
                  <Input value={make} onChange={(e) => setMake(e.target.value)} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Unit">
                  <Input value={unit} readOnly />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Quantity">
                  <Input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                  />
                </Form.Item>
              </Col>

              {/* Combined Material Rate and Material Internal Rate in a single column */}
              <Col span={3}>
                <Form.Item label="Material Rate">
                  <Input
                    type="number"
                    value={materialRate}
                    onChange={(e) => setMaterialRate(parseFloat(e.target.value))}
                  />
                </Form.Item>
                <Form.Item label="Material Internal Rate">
                  <Input
                    type={showInternalRate ? "number" : "password"}
                    value={purchaseRate}
                    onChange={(e) => setPurchaseRate(parseFloat(e.target.value))}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Material Total">
                  <Input value={materialTotal} readOnly />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Labour Rate">
                  <Input
                    type="number"
                    value={labourRate}
                    onChange={(e) => setLabourRate(parseFloat(e.target.value))}
                  />
                </Form.Item>
                <Form.Item label="Labour Internal Rate">
                  <Input
                    type={showInternalRate ? "number" : "password"}
                    value={labourInternalRate}
                    onChange={(e) => setLabourInternalRate(parseFloat(e.target.value))}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Labour Total">
                  <Input value={labourTotal} readOnly />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>

      {isSelected && item.items && item.items.length > 0 && (
        <div style={{ paddingLeft: "20px", borderLeft: "2px solid #eee" }}>
          <h4>Sub-items:</h4>
          {item.items.map((subItem, index) => (
            <BOQItem
              key={index}
              item={subItem}
              updateMegaRecordsData={(updatedSubItem) => {
                const updatedSubItems = [...item.items];
                updatedSubItems[index] = updatedSubItem;
                updateMegaRecordsData({ ...item, items: updatedSubItems });
              }}
              showInternalRate={showInternalRate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BOQItem;
