import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import Dashboard from "../features/Dashboard";
import Surveys from "../features/Surveys";
import Visits from "../features/Visits";
import NotFound from "../../pages/NotFound";
import ConductSuvey from "../features/survey/conductSurvey";
import BuildingsSurveys from "../features/survey/buildingsSurveys";
import Areas from "../features/survey/Areas";
import Equipments from "../features/survey/Equipments";
import Attendance from "../features/Attendance";
import ExportSurvey from "../features/survey/export";
import FireExtinguisherEquipments from "../features/survey/fireExtEquipments";
const EmployeeRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <MainLayout active="dashboard">
            <Dashboard />
          </MainLayout>
        }
      />

      <Route
        path="/surveys"
        element={
          <MainLayout active="surveys">
            <Surveys />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/completed"
        element={
          <MainLayout active="surveys complted">
            <Surveys completed />
          </MainLayout>
        }
      />

      <Route
        path="/survey/:surveyId/buildings"
        element={
          <MainLayout active="surveys">
            <BuildingsSurveys />
          </MainLayout>
        }
      />

      <Route
        path="/survey/:surveyId/building/:buildingId/areas"
        element={
          <MainLayout active="surveys">
            <Areas />
          </MainLayout>
        }
      />

      <Route
        path="/survey/:surveyId/building/:buildingId/area/:areaId/equipments"
        element={
          <MainLayout active="surveys">
            <Equipments />
          </MainLayout>
        }
      />

      <Route
        path="/survey/:surveyId/building/:buildingId/area/:areaId/fireExtinguisher"
        element={
          <MainLayout active="surveys">
            <FireExtinguisherEquipments/>
          </MainLayout>
        }
      />

      <Route
        path="/visit/:visitId/buildings"
        element={
          <MainLayout active="visits">
            <BuildingsSurveys />
          </MainLayout>
        }
      />

      <Route
        path="/visit/:visitId/building/:buildingId/areas"
        element={
          <MainLayout active="visits">
            <Areas />
          </MainLayout>
        }
      />

      <Route
        path="/visit/:visitId/building/:buildingId/area/:areaId/equipments"
        element={
          <MainLayout active="visits">
            <Equipments />
          </MainLayout>
        }
      />

      <Route
        path="/visit/:visitId/building/:buildingId/area/:areaId/fireExtinguisher"
        element={
          <MainLayout active="visits">
            <FireExtinguisherEquipments/>
          </MainLayout>
        }
      />

      <Route
        path="/survey/:surveyId/conduct"
        element={
          <MainLayout active="dashboard">
            <ConductSuvey />
          </MainLayout>
        }
      />

      <Route
        path="/survey/:surveyId/export"
        element={
          <MainLayout active="dashboard">
            <ExportSurvey />
          </MainLayout>
        }
      />

      <Route
        path="/visits"
        element={
          <MainLayout active="visits">
            <Visits />
          </MainLayout>
        }
      />

      <Route
        path="/visits/completed"
        element={
          <MainLayout active="surveys completed">
            <Visits completed />
          </MainLayout>
        }
      />

      <Route
        path="/attendance"
        element={
          <MainLayout active="attendance">
            <Attendance />
          </MainLayout>
        }
      />

      <Route path="/:notfound" element={<NotFound />} />
    </Routes>
  );
};

export default EmployeeRoutes;
