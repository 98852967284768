import React from 'react';
import SurveyCard from './SurveyCard';
import { Pagination, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const SurveyCards = ({surveys, isLoading, onChange, pagination, isCompleted, exportSurvey}) => {
  return (
    <div>
        {isLoading ? <LoadingOutlined /> : <>
        <div>
            {surveys.map((survey) => <SurveyCard survey={survey} isCompleted={isCompleted} exportSurvey={exportSurvey}/>)}
        </div>
        <div>
            <Pagination 
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                showSizeChanger
                onChange={(page, pageSize) => onChange(page, pageSize)}
                style={{ marginTop: "1rem", textAlign: "end" }}
            />
        </div> 
        </>}
    </div>
  )
}

export default SurveyCards;
