import {
  Button,
  Card,
  Row,
  Space,
  Table,
  Col,
  Modal,
  message,
  Badge,
} from "antd";
import React, { useEffect, useState } from "react";
import APICall from "../../../services/apiservices";
import moment from "moment";
const { confirm } = Modal;
const AcceptAttendance = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 100,
      showSizeChanger: true,
    },
  });

  const handleAprrove = (approve, all, employeeId) => {
    console.log(all);
    confirm({
      title: `Are you sure, you want to ${approve ? "approve" : "reject"}?`,
      onOk: async () => {
        try {
          const url = approve
            ? `/employee/attendance/${all ? "approveAll" : "approveSelected"}`
            : `/employee/attendance/${all ? "rejectAll" : "rejectSelected"}`;

          const response = await APICall(
            url,
            employeeId
              ? { employeeIds: [employeeId] }
              : !all
              ? { employeeIds: [...selectedRowKeys] }
              : {},
            3
          );
          message.success(`Attendances ${approve ? "Approved" : "Rejected"}`);
          setSelectedRowKeys([]);
          fetchList();
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      },
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  //   const attendanceData = [

  //     // Add more attendance data objects as needed
  //   ];

  const getMarkedAttendances = async (searchTerm, page, limit) => {
    try {
      const response = await APICall(
        `/employee/attendance/unapproved?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      message.error("failed to get employee data");
      setLoading(false);
    }
  };

  const fetchList = async () => {
    setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getMarkedAttendances(searchTerm, page, limit);
    console.log(response);
    setAttendanceData(response);
    setLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        page: page,
        total: response?.total,
        limit: limit,
      },
    });
    console.log(tableParams);
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setAttendanceData([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      key: "employeeName",
      render: (text, record) => record?.user?.name,
    },
    {
      title: "Date",
      key: "date",
      render: (record) => moment(record.date).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return (
          <Badge
            status="processing"
            color="yellow"
            text={record?.isHalfDay ? "Half Day" : "Full Day"}
          />
        );
      },
    },
    {
      title: "Extra Hours",
      dataIndex: "otHours",
      key: "exraHours",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleAprrove(true, false, record.employeeId);
            }}
          >
            Approve
          </Button>
          <Button
            type="primary"
            ghost
            onClick={() => {
              handleAprrove(false, false, record.employeeId);
            }}
          >
            Reject
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title="Marked Attendances"
            extra={
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    hasSelected
                      ? handleAprrove(true, false)
                      : handleAprrove(true, true);
                  }}
                >
                  {!hasSelected ? "Approve All" : "Approve Selected"}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    hasSelected
                      ? handleAprrove(false, false)
                      : handleAprrove(false, true);
                  }}
                >
                  {!hasSelected ? "Reject All" : "Reject Selected"}
                </Button>
              </Space>
            }
          >
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={attendanceData}
              rowKey={(record) => record.employeeId}
              size="large"
              onChange={handleTableChange}
              pagination={{
                ...tableParams.pagination,
              }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AcceptAttendance;
