import React, { useState, useEffect } from "react";
import { LuPlus } from "react-icons/lu";
import { LiaExternalLinkAltSolid } from "react-icons/lia";

import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  message,
  Modal,
  Dropdown,
  Space,
  Input,
  Image,
} from "antd";
import APICall from "../../services/apiservices";

const { confirm } = Modal;
const { Search } = Input;

const BOQItems = () => {
  const [items, setItems] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getItems = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/boqItem?page=${page}&limit=${limit}&name=${searchTerm}`,
        {},
        1
      );
      //console.log(response);
      //setItems(response.data.documents);
      //console.log(response.data.documents);
      return response.data;
      // setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch item details");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getItems(1, 10);
  // }, [reload]);

  const fetchList = async () => {
    // setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getItems(searchTerm, page, limit);
    //console.log(response)
    // console.log(response)
    setItems(response.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record.name;
      },
    },
    {
      title: "System Type",
      dataIndex: ["system", "name"],
    },
    {
      title: "Material Rate",
      dataIndex: "materialRate",
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Purchase Rate",
      dataIndex: "purchaseRate",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/items/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/items/manage/${record._id}`}>Edit</Link>,
        //       },

        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDelete(record);
        //         },
        //         danger: "true",
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Link to={`/boqItems/details/${record._id}`}>
            <Button type="primary" ghost>
              View
            </Button>
          </Link>
        </Space>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setItems([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="BOQ Items"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search an item"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/boqItems/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create BOQ Item
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={items}
            onChange={handleTableChange}
            size="small"
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default BOQItems;
