import React, { useEffect, useState } from "react";
import {
  Card,
  Descriptions,
  Button,
  message,
  Spin,
  Row,
  Col,
  Image,
  Modal,
  Space,
} from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import APICall from "../../../services/apiservices";
const { confirm } = Modal;
const ItemDetails = ({ item }) => {
  const params = useParams();
  const [itemData, setItemData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getItemDetails = async () => {
    try {
      const response = await APICall(`/item/${params.id}`, {}, 1);
      console.log(response.data);
      setItemData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getItemDetails();
  }, []);

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete item ${itemData?.name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const res = await APICall(`/item/${itemData._id}`, {}, 4);
        // setReload(true);
        message.success("Item deleted successfully!");
        navigate("/items");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Card
          title="Item Details"
          extra={
            <Space>
              <Link to={`/items/manage/${itemData?._id}`}>
                <Button type="primary">Edit</Button>
              </Link>
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Row gutter={20}>
            <Col span={6}>
              <Image src={itemData?.photoURL} />
            </Col>
            <Col span={18}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="Name">
                  {itemData?.name}
                </Descriptions.Item>

                <Descriptions.Item label="HSN">
                  {itemData?.hsn}
                </Descriptions.Item>

                <Descriptions.Item label="Price">
                  {itemData?.price}
                </Descriptions.Item>

                <Descriptions.Item label="SGST">
                  {itemData?.sgst}
                </Descriptions.Item>

                <Descriptions.Item label="CGST">
                  {itemData?.cgst}
                </Descriptions.Item>

                <Descriptions.Item label="Description">
                  {itemData?.description}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default ItemDetails;
