import React, { useEffect, useState } from "react";
import {
  Card,
  Descriptions,
  Button,
  message,
  Spin,
  Row,
  Tag,
  Space,
} from "antd";
import { Link, useParams } from "react-router-dom";
import downloadXLSX from "../../employee/features/survey/export";
import downloadFireExtinguisherXLSX from "../../employee/features/survey/fireExtExport";
import APICall from "../../services/apiservices";
import moment from "moment";
const SurveyDetails = () => {
  const params = useParams();
  const [surveyData, setSurveyData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getSurveyDetails = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await APICall(
        `/customer/${userData.customer._id}/dashboard        `,
        {},
        1
      );

      setSurveyData(response.data.data.customerSurvey);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  // const exportSurvey = async (surveyId) => {
  //   try {
  //     const res = await APICall(`/survey/${surveyId}/conducted`, {}, 1);
  //     const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
  //     const exportData = {
  //       organization: {
  //         name: "Fire Fight Safety Solutions",
  //         address:
  //           "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
  //         mobile: "9970548822",
  //         email: "info@ffss.in",
  //       },
  //       customer: {
  //         name: res?.data?.survey?.customer?.user?.name,
  //       },
  //       employee: {
  //         name: res?.data?.survey?.employee?.user?.name,
  //       },
  //       date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),
  //       buildings: res?.data?.surveyBuildings.map((sb) => {
  //         const areas = sb?.newFloors.map((nf, index) => ({
  //           area: nf?.floor?.name,
  //           srNo: index + 1,
  //           equipments: nf?.equipments ?? {
  //             adaptor: "NA",
  //             hoseBox: "NA",
  //             branchPipe: "NA",
  //             hosePipe: "NA",
  //             hydrantValve: "NA",
  //             hoseReel: "NA",
  //             fireExtinguishers: "NA",
  //             hoseBoxGlass: "NA",
  //             keyGlass: "NA",
  //             hydrantValveWheel: "NA",
  //             hoseReelBallValve: "NA",
  //           },
  //         }));
  //         const requirements = JSON.parse(JSON.stringify(requirementsArray));
  //         areas.map((area) => {
  //           Object.keys(area.equipments).map((k) => {
  //             if (area.equipments[k] == "OK") {
  //               requirements[k].existing = requirements[k].existing + 1;
  //               requirements[k].good = requirements[k].good + 1;
  //               finalRequirements[k].existing =
  //                 finalRequirements[k].existing + 1;
  //               finalRequirements[k].good = finalRequirements[k].good + 1;
  //             } else if (area.equipments[k] == "Not OK") {
  //               requirements[k].existing = requirements[k].existing + 1;
  //               requirements[k].requirement = requirements[k].requirement + 1;
  //               finalRequirements[k].existing =
  //                 finalRequirements[k].existing + 1;
  //               finalRequirements[k].requirement =
  //                 finalRequirements[k].requirement + 1;
  //             }
  //           });
  //         });
  //         return {
  //           building: sb?.building?.name,
  //           notes: sb?.information?.notes ?? "",
  //           inCharge: sb?.information?.inCharge ?? "",
  //           areas,
  //           requirements,
  //         };
  //       }),
  //     };
  //     exportData.requirements = finalRequirements;
  //     downloadXLSX(exportData, "Survey");
  //   } catch (error) {
  //     message.error(error);
  //   }
  // };

  const exportSurvey = async (surveyId) => {
    try {
      const res = await APICall(`/survey/${surveyId}/conducted`, {}, 1);

      if (!res?.data?.survey?.isFireExtinguisher) {
        const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.survey?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.survey?.employee?.user?.name,
          },
          date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),
          buildings: res.data.surveyBuildings.map((sb) => {
            const areas = sb?.newFloors.map((nf, index) => ({
              area: nf?.floor.name,
              srNo: index + 1,
              equipments: nf?.equipments ?? {
                adaptor: "NA",
                hoseBox: "NA",
                branchPipe: "NA",
                hosePipe: "NA",
                hydrantValve: "NA",
                hoseReel: "NA",
                fireExtinguishers: "NA",
                hoseBoxGlass: "NA",
                keyGlass: "NA",
                hydrantValveWheel: "NA",
                hoseReelBallValve: "NA",
              },
            }));
            const requirements = JSON.parse(JSON.stringify(requirementsArray));
            areas.map((area) => {
              Object.keys(area.equipments).map((k) => {
                if (area.equipments[k] == "OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].good = requirements[k].good + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].good = finalRequirements[k].good + 1;
                } else if (area.equipments[k] == "Not OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].requirement = requirements[k].requirement + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].requirement =
                    finalRequirements[k].requirement + 1;
                }
              });
            });
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
              requirements,
            };
          }),
        };
        exportData.requirements = finalRequirements;
        downloadXLSX(exportData, "Survey");
      } else {
        console.log(res);
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.survey?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.survey?.employee?.user?.name,
          },
          date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),

          buildings: res?.data?.surveyBuildings?.map((sb) => {
            const areas = sb?.newFloors.map((floor, index) => ({
              srNo: index + 1,
              area: floor?.floor.name,
              // fireExtinguisherInfo: {
              //   type: floor?.fireExtinguisherInfo?.type ?? "NA",
              //   capacity: floor?.fireExtinguisherInfo?.capacity ?? "NA",
              //   pressure: floor?.fireExtinguisherInfo?.pressure ?? "NA",
              //   weight: floor?.fireExtinguisherInfo?.weight ?? "NA",
              //   serialNo: floor?.fireExtinguisherInfo?.serialNo ?? "NA",
              //   installationRefillingDate: floor?.fireExtinguisherInfo
              //     ?.installationRefillingDate
              //     ? moment(
              //         floor?.fireExtinguisherInfo?.installationRefillingDate
              //       ).format("DD/MM/YYYY")
              //     : "NA",
              //   refillingDueDate: floor?.fireExtinguisherInfo?.refillingDueDate
              //     ? moment(
              //         floor?.fireExtinguisherInfo?.refillingDueDate
              //       ).format("DD/MM/YYYY")
              //     : "NA",
              //   freeAccess: floor?.fireExtinguisherInfo?.freeAccess ?? "NA",
              //   identificationTag:
              //     floor?.fireExtinguisherInfo?.identificationTag ?? "NA",
              //   forABCPressureGuage:
              //     floor?.fireExtinguisherInfo?.forABCPressureGuage ?? "NA",
              //   hoseTightFitting:
              //     floor?.fireExtinguisherInfo?.hoseTightFitting ?? "NA",
              //   isSafetyPinGood:
              //     floor?.fireExtinguisherInfo?.isSafetyPinGood ?? "NA",
              //   forCO2HoseCondition:
              //     floor?.fireExtinguisherInfo?.forCO2HoseCondition ?? "NA",
              //   forCO2HornHandle:
              //     floor?.fireExtinguisherInfo?.forCO2HornHandle ?? "NA",
              //   isFEOK: floor?.fireExtinguisherInfo?.isFEOK ?? "NA",
              //   remark: floor?.fireExtinguisherInfo?.remark ?? "NA",
              // },
              fireExtinguisherInfo: {
                type: "ABC",
                capacity: "2 KG",
                pressure: "3 Bar",
                weight: "1 KG",
                serialNo: "1245",
                installationRefillingDate: "07-09-2023",
                refillingDueDate: "06-09-2024",
                freeAccess: "Yes",
                identificationTag: "Yes",
                forABCPressureGuage: "OK",
                hoseTightFitting: "OK",
                isSafetyPinGood: "OK",
                forCO2HoseCondition: "OK",
                forCO2HornHandle: "OK",
                isFEOK: "OK",
                remark: "Everything OK",
              },
            }));
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
            };
          }),
        };

        downloadFireExtinguisherXLSX(exportData, "Survey");
      }
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSurveyDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Card
          title="Survey Details"
          extra={
            surveyData?.isConducted && (
              <Button
                type="primary"
                onClick={() => exportSurvey(surveyData?._id)}
              >
                Export Survey
              </Button>
            )
          }
        >
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Survey Number">
              {"SN" + surveyData?.surveyNo?.toString().padStart(4, "0")}
            </Descriptions.Item>
            <Descriptions.Item label="Survey Type">
              <Tag color="blue">
                {surveyData?.type.charAt(0).toUpperCase() +
                  surveyData?.type.slice(1)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Survey Date">
              {moment(surveyData?.surveyDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item
              label={surveyData?.isConducted ? "Comducted by" : "Assigned To"}
            >
              {surveyData?.employeeId?.userId?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {surveyData?.isConducted ? (
                <Tag color="green">Conducted</Tag>
              ) : (
                <Tag color="red">Pending</Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </>
  );
};

export default SurveyDetails;
