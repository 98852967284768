import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Typography,
  Statistic,
  Tag,
  message,
} from "antd";
import { AiOutlineCalendar } from "react-icons/ai";
import { VscTools } from "react-icons/vsc";
import { RiSurveyLine } from "react-icons/ri";
import APICall from "../../services/apiservices";
import moment from "moment";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [scheduledVisits, setScheduledVisits] = useState([]);

  useEffect(() => {
    fetchDashboardData();
    fetchCertificates();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const response = await APICall(
        `/customer/${userData.customer._id}/dashboard`,
        {},
        1
      );
      setDashboardData(response.data.data);
      setScheduledVisits(
        response.data.data.activeAMC?.visits.filter(
          (visit) => !visit.isConducted
        ) || []
      );
    } catch (error) {
      message.error("Failed to load dashboard data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCertificates = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));

      const reportsResponse = await APICall(
        `/report?customerId=${userData.customer._id}`,
        {},
        1
      );
      const reports = reportsResponse.data.documents.map((item) => ({
        key: item._id,
        recordName: item.type,
        issuedDate: moment(item.date).format("DD/MM/YYYY"),
        fileUrl: `/view/${item._id}`,
      }));

      const amcResponse = await APICall(
        `/amc?customerId=${userData.customer._id}`,
        {},
        1
      );
      const amcReports = amcResponse.data.documents.map((item) => ({
        key: item._id,
        recordName: `AMC Report - ${item.amcNo.toString().padStart(4, "0")}`,
        issuedDate: moment(item.contractDate).format("DD/MM/YYYY"),
        fileUrl: `/amcs/${item._id}`,
      }));

      setCertificates([...reports, ...amcReports]);
    } catch (error) {
      message.error("Failed to load certificates.");
    }
  };

  const certificateColumns = [
    {
      title: "Record Name",
      dataIndex: "recordName",
      key: "recordName",
    },
    {
      title: "Issued Date",
      dataIndex: "issuedDate",
      key: "issuedDate",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            if (record.recordName === "Form A") {
              return window.open(`/view/formA/${record.key}`, "_blank")
            } else if (record.recordName === "Form B") {
                return window.open(`/view/formB/${record.key}`, "_blank")
            } else if (record.recordName.split(" ")[0] === "Installation") {
                return window.open(`/view/installationCertificate/${record.key}`, "_blank")
            } else if (record.recordName.split(" ")[0] === "Training") {
                return window.open(`/view/trainingCertificate/${record.key}`, "_blank")
            } else if (record.recordName.split(" ")[0] === "Test") {
                return window.open(`/view/testingCertificate/${record.key}`, "_blank")
            } else if (record.recordName.split(" ")[0] === "AMC") {
              return window.open(`/amcs/${record.key}/export`, "_blank")
            }
          }}
        >
          View File
        </Button>
      ),
    },
  ];

  const visitColumns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => moment(record.scheduledDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => <Tag color={record.isConducted ? "green" : "red"}>{record.isConducted ? "Completed" : "Pending"}</Tag>,
    },
  ];

  return (
    <div style={{ padding: "1rem" }}>
      <Row>
        <Col span={24}>
          <Typography.Title level={5} style={{ color: "#024fa1" }}>
            Welcome to
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Title level={4} type="danger">
            Fire Fight Safety Solutions
          </Typography.Title>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card loading={loading}>
            <Statistic
              title="Survey Conducted"
              prefix={<AiOutlineCalendar style={{ color: "#024fa1" }} />}
              value={
                moment(dashboardData?.customerSurvey?.surveyDate).format(
                  "DD/MM/YYYY"
                ) || "N/A"
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card loading={loading}>
            <Statistic
              title="AMC Expiring In"
              prefix={<VscTools style={{ color: "#024fa1" }} />}
              value={dashboardData?.daysToExpiry || "N/A"}
              suffix="days"
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card loading={loading}>
            <Statistic
              title="Visits in Current AMC"
              prefix={<RiSurveyLine style={{ color: "#024fa1" }} />}
              value={dashboardData?.visitsInActiveAMCCount || "N/A"}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card loading={loading}>
            <Statistic
              title="All Certificates"
              prefix={<AiOutlineCalendar style={{ color: "#024fa1" }} />}
              value={certificates.length || "N/A"}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "2rem" }}>
        <Col xs={24} lg={12}>
          <Card title="Download Certificates" loading={loading}>
            <Table
              columns={certificateColumns}
              dataSource={certificates}
              pagination={{ pageSize: 10 }}
              rowKey="key"
              size="small"
            />
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card title="Scheduled Visits" loading={loading}>
            <Table
              columns={visitColumns}
              dataSource={scheduledVisits}
              pagination={false}
              rowKey="_id"
              size="small"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
