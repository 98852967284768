import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import CustomTablePDF from "./table";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    marginTop: 16,
    paddingTop: '3cm',
    paddingBottom: '4cm',
  },
  letterheader: {
    height: "150px",
    paddingHorizontal: 16,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 14,
    paddingHorizontal: 32,
  },
  header: {
    flexDirection: "row",
    paddingBottom: 16,
    fontSize: 10,
    fontFamily: "Times-Roman",
    paddingHorizontal: 16,
    alignItems: "flex-start",
  },
  headerSectionLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  headerSectionRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  headerBlock: {
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    padding: 8,
    margin: 16,
  },
  letterfooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

let tableCol = {
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};

const fields = [
  {
    title: "Field",
    custom: true,
    component: (item) => `${item.field}`,
    width: "30%",
  },
  {
    title: "Information",
    custom: true,
    component: (item) => `${item.information}`,
    width: "70%",
  },
];

const visitFields = [
  {
    title: "Visit Number",
    custom: true,
    component: (item) => `${item.number}`,
    width: "30%",
  },
  {
    title: "Scheduled Date",
    custom: true,
    component: (item) => `${item.date}`,
    width: "70%",
  },
];

// Create Document Component
const AMC = ({
  letterheader,
  letterfooter,
  date,
  place = "",
  amcNo,
  customer,
  contactPerson,
  contractDate,
  expiryDate,
  type,
  systems,
  notes,
  workOrderNo,
  contractAmount,
  visitsCount,
  visits,
}) => (
  <Page size="A4" style={[styles.page, letterfooter && { paddingBottom: 150 }]}>
    ?
    {letterheader && (
      <View fixed style={styles.letterheader}>
        {<Image src={letterheader} />}
      </View>
    )}
    <View
      style={{
        ...styles.title,
        paddingTop: letterheader ? 16 : 32,
        paddingBottom: 12,
      }}
    >
      <Text
        style={{
          fontFamily: "Times-Bold",
          fontSize: 26,
          textDecoration: "underline",
        }}
      >
        ANNUAL MAINTENANCE CONTRACT
      </Text>
    </View>
    <View style={{ ...styles.header, paddingBottom: 8, fontSize: 12 }}>
      <View style={styles.headerSectionLeft}>
        <Text style={{ fontFamily: "Times-Bold" }}>
          Date: <Text style={{ fontFamily: "Times-Roman" }}>{date}</Text>
        </Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text style={{ fontFamily: "Times-Bold" }}>
          REF No: <Text style={{ fontFamily: "Times-Roman" }}>{amcNo}</Text>
        </Text>
      </View>
    </View>
    <View style={{ ...styles.title, paddingTop: 16, paddingBottom: 8 }}>
      <Text style={{ fontFamily: "Times-Bold", fontSize: 18 }}>
        CONTRACT DETAILS
      </Text>
    </View>
    <View style={{ paddingHorizontal: 16 }}>
      <CustomTablePDF
        fields={fields}
        data={[
          {
            field: "Customer Name",
            information: customer.name,
          },
          {
            field: "Address",
            information: customer.address,
          },
          {
            field: "Contact Person",
            information: contactPerson.name + " (" + contactPerson.mobile + ")",
          },
          {
            field: "AMC Type",
            information: type,
          },
          {
            field: "AMC Contract Date",
            information: contractDate,
          },
          {
            field: "AMC Expiry Date",
            information: expiryDate,
          },
          {
            field: "AMC For",
            information: systems.join(", "),
          },
          {
            field: "Notes",
            information: notes,
          },
          {
            field: "Work Order No.",
            information: workOrderNo,
          },
          {
            field: "AMC Contract Amount",
            information: contractAmount,
          },
          {
            field: "Number of Visits",
            information: visitsCount,
          },
        ]}
        amc
      />
    </View>
    <View style={{ paddingLeft: 16, paddingRight: 256, paddingVertical: 16 }}>
      <CustomTablePDF fields={visitFields} data={visits} />
    </View>
    <View style={{ ...styles.body }} break>
      <Text
        style={{ fontFamily: "Times-Bold", fontSize: 14, paddingBottom: 16 }}
      >
        ANNEXURE:-
      </Text>
      <Text
        style={{
          fontFamily: "Times-Roman",
          fontSize: 12,
          textAlign: "justify",
          paddingLeft: 16,
        }}
      >
        {`• The period of contract will be 12 months from the date of work order/ invoice whichever is decided and applicable.The contract shall remain effective normally for one year. However, customer reserves the right to renew the contract for another year with decided increase rate depending on satisfactory performance of the Fire Fight Safety Solutions.\n
• The maintenance charges shall be paid to the Fire Fight Safety Solutions along with the purchase order as per decided terms.\n
• Rates quoted by the Fire Fight Safety Solutions shall be firm for the entire period of the contract.\n
• The rates quoted shall include cost of all tools and tackles, manpower cost including wages, liveries etc., taxes and duties, levies, etc., payable to the appropriate authority. No extra payment over and above the rate quoted shall be made to the Fire Fight Safety Solutions. in this respect.\n
• The Fire Fight Safety Solutions. shall be solely responsible for all injury to the workmen.\n
• Before quoting, the Fire Fight Safety Solutions. may visit the site and make himself familiar with the site, nature and conditions of work involved.\n
• The Fire Fight Safety Solutions. or his authorized representative shall be present at site and closely supervise / monitor the entire work entrusted to him and report the progress of work / repairs etc. that needs to be taken up, to the caretaker / concerned officer regularly.\n
• The rates quoted shall include cost of all tools & tackles etc., in addition to the manpower cost (wages, liveries, etc.).\n
• The man power mentioned above is indicative. The Fire Fight Safety Solutions, if required, shall provide additional manpower to execute the work properly and in reasonable time, cost of which will be reimbursed on the basis of quoted cost of Fire Fight Safety Solutions.\n
• The man power should be experienced & subject to removal if not found satisfactory without any obligations.\n
• No conveyance will be paid separately for work\n
• All the dispute , which arises between the parties, shall be treated at thr Courts of Pune alone.\n
• To comply with the applicable provisions of law.\n
• Inspection, Cheacking ,Servicing & Activation of all Hydrant Valve and Fire Extinguisher with Testing and Demo.\n
            `}
      </Text>
      <Text
        style={{
          fontFamily: "Times-Roman",
          fontSize: 12,
          textAlign: "justify",
          paddingLeft: 32,
        }}
      >
        {`1) Thorough Cleaning:- The Fire Extinguisher and Hydrant Accessories is checked for dust deposits and cleaned thoroughly.\n 
2) Testing Of System:- For Fire Extinguisher and Hydrant Pump Checking of the pressure gauge which in perfect working condition or not.\n 
3) Four Visit in one year:- Our Expert or technician will do check up.\n 
4) Service Terms:- Four fixed services will be given within the year & other Two will be free on call.\n
5) Fire Audit Report:- Form B will be given twice in a year & at the end of the inspection Fire Audit Report will be given.\n 
6) Termination period should be one month.\n 
7) Demo & Training will be given twice in one year.
            `}
      </Text>

      <Text
        style={{ fontFamily: "Times-Bold", fontSize: 14, paddingBottom: 8 }}
      >
        SCOPE:-
      </Text>
      <Text
        style={{ fontFamily: "Times-Roman", fontSize: 12, paddingBottom: 16 }}
      >
        The scope under this work entails the following:-
      </Text>
      <Text
        style={{
          fontFamily: "Times-Roman",
          fontSize: 12,
          textAlign: "justify",
          paddingLeft: 16,
        }}
      >
        {`• Testing of various Fire Fighting Systems as per schedule.\n
• Maintenance of Records.\n
• The period of replacement of parts that affect the system is indicated below. Those which are considered essential shall be rectified immediately.\n
• To guide security person for extinguishing fire at the time of actual fire.\n
• At the end of the inspection AMC Visit Report will be provided.\n
• Replacement of defective equipments/parts shall not be covered in the NonComprehensive AMC rates.\n
• List of all equipment with location and quantity.\n
• All Hydrant points will be checked for flow of water, condition of inner rubber seal and spring and locking latch condition.(IS: 13039)\n
• All hoses of fire hydrant will be checked for any damage, cut, and any wear & tear.\n
• All hose reel point will be checked for flow of water, condition of hoses, and cock.\n
• Maintenance of the fire pumps will be carried out as per maintenance schedule.\n
• Greasing and oiling of Fire pumps etc. will be carried out.\n
• Hose boxes will be checked and repaired if necessary.\n
• Fire Service Inlet Point will be checked and maintained.
            `}
      </Text>

      <Text
        style={{ paddingTop: 32, paddingBottom: 16, fontFamily: "Times-Bold" }}
      >
        {`Place: ${place}\n\nDate: ${date}`}
      </Text>
    </View>
    <View
      style={{
        ...styles.header,
        paddingBottom: 64,
        paddingTop: 32,
        fontSize: 12,
        paddingHorizontal: 32,
      }}
    >
      <View style={styles.headerSectionLeft}>
        <Text style={{ fontFamily: "Times-Bold" }}>
          {`For ${customer.name}`}
        </Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text style={{ fontFamily: "Times-Bold" }}>
          For Fire Fight Safety Solutions
        </Text>
      </View>
    </View>
    <View
      style={{
        ...styles.header,
        paddingBottom: 8,
        fontSize: 12,
        paddingHorizontal: 32,
      }}
    >
      <View style={styles.headerSectionLeft}>
        <Text style={{ fontFamily: "Times-Roman" }}>Authorised Signatory</Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text style={{ fontFamily: "Times-Roman" }}>Authorised Signatory</Text>
      </View>
    </View>
    {letterheader && (
      <View fixed style={styles.letterfooter}>
        {<Image src={letterfooter} />}
      </View>
    )}
  </Page>
);

export default AMC;
