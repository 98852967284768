import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import MainLayout from "../../components/Layout/MainLayout";
import NotFound from "../../pages/NotFound";
import Login from "../../pages/Login";
import Employees from "../pages/Employees";
import ManageEmployee from "../features/employee/ManageEmployees";
import EmployeeDetails from "../features/employee/EmployeeDetails";
import EmployeeAttendance from "../features/employee/EmployeeAttendance";
import Customers from "../pages/Customers";
import ManageCustomer from "../features/customers/ManageCustomers";
import CustomerDetails from "../features/customers/CustomerDetails";
import Items from "../pages/Items";
import ManageItems from "../features/items/ManageItems";
import ItemDetails from "../features/items/ItemDetails";
import Quotations from "../pages/Quotations";
import AMCs from "../pages/AMCs";
import Visits from "../pages/Visits";
import Surveys from "../pages/Surveys";
import ManageVisits from "../features/visits/ManageVisits";
import VisitDetails from "../features/visits/VisitDetails";
import ManageAMCs from "../features/amc/ManageAMCs";
import AmcExport from "../features/amc/AmcExport";
import AMCDetails from "../features/amc/AmcDetails";

import ManageQuoatation from "../features/quotations/ManageQuoatation";
import QuoatationDetails from "../features/quotations/QuoatationDetails";

import FormA from "../features/reports/FormA";
import FormB from "../features/reports/FormB";
import TrainingCertificateForm from "../features/reports/TrainingCertificate";
import InstallationCertificateForm from "../features/reports/InstallationCertificateForm";
import TestingCertificateForm from "../features/reports/TestingCertificateForm";

import FormAReports from "../pages/FormAReports";
import FormBReports from "../pages/FormBReports";
import TrainingCertificateList from "../pages/TrainingCertificate";
import InstallationCertificates from "../pages/InstallationCertificates";
import TestingCertificate from "../pages/TestingCertificate";

import ManageSurvey from "../features/survey/ManageSurvey";
import SurveyDetails from "../features/survey/SurveyDetails";
import OfferLetterDetails from "../features/employee/OfferLetter";
import AcceptAttendance from "../features/employee/AcceptAttendance";

import Salary from "../features/employee/Salary";
import PurchaseOrders from "../pages/purchaseOrders";
import ManagePurchaseOrder from "../features/purchaseOrder/ManagePurchaseOrder";
import PODetails from "../features/purchaseOrder/PODetails";
import Leads from "../pages/lead";
import ManageLead from "../features/lead/ManageLead";
import LeadDetails from "../features/lead/LeadDetails";
import Tasks from "../pages/task";
import TaskDetails from "../features/task/taskDetails";
import ManageTask from "../features/task/ManageTask";
import LicenseCopies from "../pages/licenseCopy";
import CashLedgers from "../pages/cashLedger";
import CashLedgerDetails from "../features/cashLedger/cashLedgerDetails";
import ManageCashLedger from "../features/cashLedger/ManageCashLedger";
import LicenseCopyDetails from "../features/licenseCopy/licenseCopyDetails";
import BOQ from "../pages/BOQ";
import ManageBOQ from "../features/boq/ManageBOQ";
import BOQItems from "../pages/BOQItems";
import ManageBOQItems from "../features/boqItems/ManageBOQItems";
import BOQItemDetails from "../features/boqItems/BOQItemDetails";
import BOQDetails from "../features/boq/BOQDetails";

import ManageLicenseCopy from "../features/licenseCopy/ManageLicenseCopy";

const SuperAdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <MainLayout active="dashboard">
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/customers"
        element={
          <MainLayout active="customers">
            <Customers isActive={true} />
          </MainLayout>
        }
      />
      <Route
        path="/customers/inactive"
        element={
          <MainLayout active="customers">
            <Customers isActive={false} />
          </MainLayout>
        }
      />

      <Route
        path="/customers/manage"
        element={
          <MainLayout active="customers">
            <ManageCustomer />
          </MainLayout>
        }
      />
      <Route
        path="/customers/manage/:customerId"
        element={
          <MainLayout active="customers">
            <ManageCustomer isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/customers/:customerId"
        element={
          <MainLayout active="customers">
            <CustomerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/employees"
        element={
          <MainLayout active="employees">
            <Employees isActive={true} />
          </MainLayout>
        }
      />
      <Route
        path="/employees/inactive"
        element={
          <MainLayout active="employees">
            <Employees isActive={false} />
          </MainLayout>
        }
      />
      <Route
        path="/employees/attendance"
        element={
          <MainLayout active="employees">
            <EmployeeAttendance />
          </MainLayout>
        }
      />
      <Route
        path="/employees/attendance/accept"
        element={
          <MainLayout active="employees">
            <AcceptAttendance />
          </MainLayout>
        }
      />
      <Route
        path="/employees/manage"
        element={
          <MainLayout active="employees">
            <ManageEmployee />
          </MainLayout>
        }
      />
      <Route
        path="/employees/manage/:employeeId"
        element={
          <MainLayout active="employees">
            <ManageEmployee isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/employees/:employeeId"
        element={
          <MainLayout active="employees">
            <EmployeeDetails />
          </MainLayout>
        }
      />
      <Route
        path="/employees/salary"
        element={
          <MainLayout active="employees">
            <Salary />
          </MainLayout>
        }
      />
      <Route
        path="/employees/:employeeId/offerLetter"
        element={
          <MainLayout active="employees">
            <OfferLetterDetails />
          </MainLayout>
        }
      />
      <Route
        path="/items"
        element={
          <MainLayout active="items">
            <Items />
          </MainLayout>
        }
      />
      <Route
        path="/items/:id"
        element={
          <MainLayout active="items">
            <ItemDetails />
          </MainLayout>
        }
      />
      <Route
        path="/items/manage"
        element={
          <MainLayout active="items">
            <ManageItems />
          </MainLayout>
        }
      />
      <Route
        path="/items/manage/:id"
        element={
          <MainLayout active="items">
            <ManageItems isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/quotations"
        element={
          <MainLayout active="quotations">
            <Quotations />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/:quotationId"
        element={
          <MainLayout active="quotations">
            <QuoatationDetails />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/manage"
        element={
          <MainLayout active="items">
            <ManageQuoatation />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/manage/:id"
        element={
          <MainLayout active="items">
            <ManageQuoatation isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/amcs"
        element={
          <MainLayout active="amcs">
            <AMCs />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/details/:amcId"
        element={
          <MainLayout active="amcs">
            <AMCDetails />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/:amcId"
        element={
          <MainLayout active="amcs">
            <AmcExport />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/manage"
        element={
          <MainLayout active="amcs">
            <ManageAMCs />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/manage/:id"
        element={
          <MainLayout active="amcs">
            <ManageAMCs isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/purchaseOrder"
        element={
          <MainLayout active="purchaseOrder">
            <PurchaseOrders />
          </MainLayout>
        }
      />
      <Route
        path="/purchaseOrder/details/:poId"
        element={
          <MainLayout active="purchaseOrder">
            <PODetails />
          </MainLayout>
        }
      />

      <Route
        path="/boq"
        element={
          <MainLayout active="boq">
            <BOQ />
          </MainLayout>
        }
      />
      <Route
        path="/boq/details/:boqId"
        element={
          <MainLayout active="boq">
            <BOQDetails />
          </MainLayout>
        }
      />
      <Route
        path="/boq/manage"
        element={
          <MainLayout active="boq">
            <ManageBOQ />
          </MainLayout>
        }
      />
      <Route
        path="/boq/manage/:boqId"
        element={
          <MainLayout active="boq">
            <ManageBOQ isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/purchaseOrder/manage"
        element={
          <MainLayout active="purchaseOrder">
            <ManagePurchaseOrder />
          </MainLayout>
        }
      />
      <Route
        path="/purchaseOrder/manage/:id"
        element={
          <MainLayout active="purchaseOrder">
            <ManagePurchaseOrder isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/lead"
        element={
          <MainLayout active="lead">
            <Leads />
          </MainLayout>
        }
      />
      <Route
        path="/lead/details/:leadId"
        element={
          <MainLayout active="lead">
            <LeadDetails />
          </MainLayout>
        }
      />
      <Route
        path="/lead/manage"
        element={
          <MainLayout active="lead">
            <ManageLead />
          </MainLayout>
        }
      />
      <Route
        path="/lead/manage/:id"
        element={
          <MainLayout active="lead">
            <ManageLead isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/task"
        element={
          <MainLayout active="task">
            <Tasks />
          </MainLayout>
        }
      />
      <Route
        path="/task/details/:taskId"
        element={
          <MainLayout active="task">
            <TaskDetails />
          </MainLayout>
        }
      />
      <Route
        path="/task/manage"
        element={
          <MainLayout active="task">
            <ManageTask />
          </MainLayout>
        }
      />
      <Route
        path="/task/manage/:id"
        element={
          <MainLayout active="task">
            <ManageTask isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/cashLedger"
        element={
          <MainLayout active="cashLedger">
            <CashLedgers />
          </MainLayout>
        }
      />
      <Route
        path="/cashLedger/details/:cashLedgerId"
        element={
          <MainLayout active="cashLedger">
            <CashLedgerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/cashLedger/manage"
        element={
          <MainLayout active="cashLedger">
            <ManageCashLedger />
          </MainLayout>
        }
      />
      <Route
        path="/cashLedger/manage/:id"
        element={
          <MainLayout active="cashLedger">
            <ManageCashLedger isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/boqItems"
        element={
          <MainLayout active="boqItems">
            <BOQItems />
          </MainLayout>
        }
      />
      <Route
        path="/boqItems/details/:boqItemId"
        element={
          <MainLayout active="boqItems">
            <BOQItemDetails />
          </MainLayout>
        }
      />
      <Route
        path="/boqItems/manage"
        element={
          <MainLayout active="boqItems">
            <ManageBOQItems />
          </MainLayout>
        }
      />
      <Route
        path="/boqItems/manage/:id"
        element={
          <MainLayout active="boqItems">
            <ManageBOQItems isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/licenseCopy"
        element={
          <MainLayout active="licenseCopy">
            <LicenseCopies />
          </MainLayout>
        }
      />
      <Route
        path="/licenseCopy/details/:licenseCopyId"
        element={
          <MainLayout active="licenseCopy">
            <LicenseCopyDetails />
          </MainLayout>
        }
      />
      <Route
        path="/licenseCopy/manage"
        element={
          <MainLayout active="licenseCopy">
            <ManageLicenseCopy />
          </MainLayout>
        }
      />
      <Route
        path="/licenseCopy/manage/:id"
        element={
          <MainLayout active="licenseCopy">
            <ManageLicenseCopy isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/visits"
        element={
          <MainLayout active="visits">
            <Visits />
          </MainLayout>
        }
      />
      <Route
        path="/visits/:id"
        element={
          <MainLayout active="visits">
            <VisitDetails />
          </MainLayout>
        }
      />
      <Route
        path="/visits/manage"
        element={
          <MainLayout active="visits">
            <ManageVisits />
          </MainLayout>
        }
      />
      <Route
        path="/visits/manage/:id"
        element={
          <MainLayout active="visits">
            <ManageVisits isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/surveys"
        element={
          <MainLayout active="surveys">
            <Surveys />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/completed"
        element={
          <MainLayout active="surveys">
            <Surveys conducted />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/:surveyId"
        element={
          <MainLayout active="surveys">
            <SurveyDetails />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/manage"
        element={
          <MainLayout active="surveys">
            <ManageSurvey />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/manage/:surveyId"
        element={
          <MainLayout active="surveys">
            <ManageSurvey isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/reports/formA"
        element={
          <MainLayout active="reports">
            <FormAReports />
          </MainLayout>
        }
      />
      <Route
        path="/generatereport/formA"
        element={
          <MainLayout active="reports">
            <FormA />
          </MainLayout>
        }
      />
      <Route
        path="/view/formA/:id"
        element={
          <MainLayout active="reports">
            <FormA view />
          </MainLayout>
        }
      />

      <Route
        path="/edit/formA/:id"
        element={
          <MainLayout active="reports">
            <FormA edit />
          </MainLayout>
        }
      />

      <Route
        path="/reports/formB"
        element={
          <MainLayout active="reports">
            <FormBReports />
          </MainLayout>
        }
      />
      <Route
        path="/generatereport/formB"
        element={
          <MainLayout active="reports">
            <FormB />
          </MainLayout>
        }
      />
      <Route
        path="/view/formB/:id"
        element={
          <MainLayout active="reports">
            <FormB view />
          </MainLayout>
        }
      />
      <Route
        path="/edit/formB/:id"
        element={
          <MainLayout active="reports">
            <FormB edit />
          </MainLayout>
        }
      />
      <Route
        path="/reports/trainingCertificate"
        element={
          <MainLayout active="reports">
            <TrainingCertificateList />
          </MainLayout>
        }
      />
      <Route
        path="/generatereport/trainingCertificate"
        element={
          <MainLayout active="reports">
            <TrainingCertificateForm />
          </MainLayout>
        }
      />
      <Route
        path="/view/trainingCertificate/:id"
        element={
          <MainLayout active="reports">
            <TrainingCertificateForm view />
          </MainLayout>
        }
      />
      <Route
        path="/edit/trainingCertificate/:id"
        element={
          <MainLayout active="reports">
            <TrainingCertificateForm edit />
          </MainLayout>
        }
      />
      <Route
        path="/reports/installationCertificate"
        element={
          <MainLayout active="reports">
            <InstallationCertificates />
          </MainLayout>
        }
      />
      <Route
        path="/generatereport/installationCertificate"
        element={
          <MainLayout active="reports">
            <InstallationCertificateForm />
          </MainLayout>
        }
      />
      <Route
        path="/view/installationCertificate/:id"
        element={
          <MainLayout active="reports">
            <InstallationCertificateForm view />
          </MainLayout>
        }
      />
      <Route
        path="/edit/installationCertificate/:id"
        element={
          <MainLayout active="reports">
            <InstallationCertificateForm edit />
          </MainLayout>
        }
      />
      <Route
        path="/reports/testCertificate"
        element={
          <MainLayout active="reports">
            <TestingCertificate />
          </MainLayout>
        }
      />
      <Route
        path="/generatereport/testingCertificate"
        element={
          <MainLayout active="reports">
            <TestingCertificateForm />
          </MainLayout>
        }
      />
      <Route
        path="/view/testingCertificate/:id"
        element={
          <MainLayout active="reports">
            <TestingCertificateForm view />
          </MainLayout>
        }
      />
      <Route
        path="/edit/testingCertificate/:id"
        element={
          <MainLayout active="reports">
            <TestingCertificateForm edit />
          </MainLayout>
        }
      />

      <Route
        path="/settings"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      />
      <Route
        path="/new"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      />

      <Route path="/:notfound" element={<NotFound />} />
    </Routes>
  );
};

export default SuperAdminRoutes;
