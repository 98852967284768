import React from "react";
import { utils, write } from "xlsx-js-style";

// const ExportSurvey = () => {
const allBorder = {
  left: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  right: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  bottom: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  top: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
};

const organizationHeaderStyle = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  font: {
    bold: true,
    sz: 28,
    name: "Times New Roman",
  },
  fill: {
    fgColor: { rgb: "C2D69B" },
  },
  border: allBorder,
};

const organizationSubHeaderStyle = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  font: {
    bold: true,
    sz: 14,
    name: "Times New Roman",
  },
  fill: {
    fgColor: { rgb: "C2D69B" },
  },
  border: allBorder,
};

const boldStyle = {
  font: {
    bold: true,
    sz: 12,
    name: "Times New Roman",
  },
  border: allBorder,
};

const centerAlignment = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
};

const columnTitleStyle = {
  ...boldStyle,
  ...centerAlignment,
  fill: {
    fgColor: { rgb: "FABF8F" },
  },
  font: {
    ...boldStyle.font,
    sz: 10,
  },
};

const getFinalArray = (array, placeholder = "") => {
  const totalLength = 18;
  const finalArray = [...array];
  const otherArray = [];
  if (finalArray.length <= totalLength)
    for (let i = finalArray.length; i < totalLength; i++)
      otherArray.push(placeholder);
  const newFinalArray = [...finalArray, ...otherArray];
  return newFinalArray;
};

// const exportData = {
//   organization: {
//     name: "Fire Fight Safety Solutions",
//     address:
//       "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
//     mobile: "9970548822",
//     email: "info@ffss.in",
//   },
//   customer: {
//     name: "The Wood's Housing Society, Kalewadi.",
//   },
//   employee: {
//     name: "Samir Kale",
//   },
//   date: "07/08/2023",
//   buildings: [
//     {
//       building: "Capital A",
//       notes:
//         "1. All Hydrant Valve need servicing\n2. Terrace Booster Pump Need Servicing\n3. Alternate Floor should need fire extinguishers.\n4. Hose Box Glass 1st, 3rd, 5th and Basement Front Side Not OK\n5. Key Glass Basement Front Side Not OK and Hydrant Valve Not OK",
//       areas: [
//         {
//           srNo: 1,
//           area: "Floor 1",
//           fireExtinguisherInfo: {
//             type: "ABC",
//             capacity: "2 KG",
//             pressure: "3 Bar",
//             weight: "1 KG",
//             serialNo: "1245",
//             installationRefillingDate: "07-09-2023",
//             refillingDueDate: "06-09-2024",
//             freeAccess: "Yes",
//             identificationTag: "Yes",
//             forABCPressureGuage: "OK",
//             hoseTightFitting: "OK",
//             isSafetyPinGood: "OK",
//             forCO2HoseCondition: "OK",
//             forCO2HornHandle: "OK",
//             isFEOK: "OK",
//             remark: "Everything OK",
//           },
//         },
//         {
//           srNo: 2,
//           area: "Floor 2",
//           fireExtinguisherInfo: {
//             type: "ABC",
//             capacity: "2 KG",
//             pressure: "3 Bar",
//             weight: "1 KG",
//             serialNo: "1245",
//             installationRefillingDate: "07-09-2023",
//             refillingDueDate: "06-09-2024",
//             freeAccess: "Yes",
//             identificationTag: "Yes",
//             forABCPressureGuage: "OK",
//             hoseTightFitting: "OK",
//             isSafetyPinGood: "OK",
//             forCO2HoseCondition: "OK",
//             forCO2HornHandle: "OK",
//             isFEOK: "OK",
//             remark: "Everything OK",
//           },
//         },
//       ],
//     },
//   ],
// };

const getBuildingSheet = ({
  organization,
  customer,
  employee,
  date,
  building,
  type = "Survey",
}) => {
  const buildingAreas = [
    {
      area: "Floor 1",
      values: {
        type: "ABC",
        capacity: "6 Kg",
        pressure: "15 Bar",
        weight: "NA",
        serialNo: "1370",
        installationRefillingDate: "18-03-2019",
        refillingDueDate: "18-03-2024",
        freeAccess: "Yes",
        identificationTag: "Yes",
        forABCPressureGuage: "OK",
        hoseTightFitting: "OK",
        isSafetyPinGood: "OK",
        forCO2HoseCondition: "NA",
        forCO2HornHandle: "NA",
        isFEOK: "OK",
        remark: "OK",
      },
    },
  ];
  const areas = [];

  building.areas.map((area, index) =>
    areas.push([
      { v: `${index + 1}`, s: { ...boldStyle, ...centerAlignment } },
      { v: area.area, s: boldStyle },
      ...Object.values(area.fireExtinguisherInfo).map((e) => ({
        v: e,
        s: centerAlignment,
      })),
    ])
  );

  const data = [
    getFinalArray([{ v: organization.name, s: organizationHeaderStyle }]),
    getFinalArray([{ v: organization.address, s: organizationSubHeaderStyle }]),
    getFinalArray([
      {
        v: `Contact: ${organization.mobile} E-Mail: ${organization.email}`,
        s: organizationSubHeaderStyle,
      },
    ]),
    getFinalArray([""]),
    getFinalArray([
      { v: customer.name, s: { ...boldStyle, ...centerAlignment } },
    ]),
    getFinalArray([
      {
        v: `Fire Extinguisher ${type}`,
        s: { ...boldStyle, ...centerAlignment },
      },
    ]),
    getFinalArray([
      { v: `Date: ${date}`, s: { ...boldStyle, ...centerAlignment } },
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      {
        v: `Conducted by: ${employee.name}`,
        s: { ...boldStyle, ...centerAlignment },
      },
    ]),
    getFinalArray([
      { v: "Sr. No.", s: columnTitleStyle },
      { v: "Location", s: columnTitleStyle },
      { v: "Type of Fire Extinguisher", s: columnTitleStyle },
      { v: "Capacity", s: columnTitleStyle },
      { v: "Pressure (Bar)", s: columnTitleStyle },
      { v: "Weight (Kg)", s: columnTitleStyle },
      { v: "Serial No.", s: columnTitleStyle },
      { v: "Installation Year/Refilling Date", s: columnTitleStyle },
      { v: "Refilling Due Date", s: columnTitleStyle },
      { v: "Free Access", s: columnTitleStyle },
      { v: "Identification Tag", s: columnTitleStyle },
      { v: "For ABC type FE - Check Press Gauge - Green", s: columnTitleStyle },
      { v: "Hose for tight fitting and Blockage", s: columnTitleStyle },
      { v: "Check Safety Pin is in Good condition", s: columnTitleStyle },
      { v: "For CO2- Check Hose condition, Tightening", s: columnTitleStyle },
      { v: "For CO2- Check for Horn & Handle", s: columnTitleStyle },
      { v: "Confirm FE is ok & ready for use", s: columnTitleStyle },
      { v: "Remark", s: columnTitleStyle },
    ]),
    ...areas,
    getFinalArray([{ v: `Notes: ${building.notes}`, s: boldStyle }]),
  ];

  // Create a worksheet
  const ws = utils.aoa_to_sheet(data);

  // Add border to each cell
  Object.keys(ws).map((k) => {
    if (typeof ws[k] == "object") {
      ws[k].s = ws[k].s
        ? { ...ws[k].s, border: allBorder }
        : { border: allBorder };
    }
  });

  ws["!rows"] = [{ hpt: 40 }, { hpt: 20 }, { hpt: 20 }];

  ws["!cols"] = [
    { wch: 8 },
    { wch: 20 },
    { wch: 12 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
  ];

  const equipmentBreakPadding = areas.length + 8;

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } }, // Header
    { s: { r: 1, c: 0 }, e: { r: 1, c: 17 } }, // Subheader
    { s: { r: 2, c: 0 }, e: { r: 2, c: 17 } }, // Subheader
    { s: { r: 3, c: 0 }, e: { r: 3, c: 17 } }, // Break

    { s: { r: 4, c: 0 }, e: { r: 4, c: 17 } }, // Customer Name
    { s: { r: 5, c: 0 }, e: { r: 5, c: 17 } }, // Survey Name

    { s: { r: 6, c: 0 }, e: { r: 6, c: 8 } }, // Date
    { s: { r: 6, c: 9 }, e: { r: 6, c: 17 } }, // Done by

    {
      s: { r: equipmentBreakPadding, c: 0 },
      e: { r: equipmentBreakPadding, c: 17 },
    }, // Notes
  ];
  ws["!merges"] = merge;

  return ws;
};

const downloadFireExtinguisherXLSX = (exportData, type = "Survey") => {
  // Create a workbook and add the worksheet
  const wb = utils.book_new();

  for (let i = 0; i < exportData.buildings.length; i++) {
    const building = exportData.buildings[i];
    const ws = getBuildingSheet({
      organization: exportData.organization,
      customer: exportData.customer,
      employee: exportData.employee,
      date: exportData.date,
      building,
      type,
    });

    let sheetName;
    if (i < 2) {
        // Use the original name for the first two buildings
        sheetName = building.building.substring(0, 31);
    } else {
        // Use index as sheet name if building name is longer than 25 characters
        sheetName = building.building.length > 31 ? `${i}` : building.building.substring(0, 31);
    }

    utils.book_append_sheet(wb, ws, sheetName);
}


  // Convert the workbook to a buffer
  const buffer = write(wb, { bookType: "xlsx", type: "buffer" });

  // Convert the buffer to a Blob
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link
  const downloadLink = URL.createObjectURL(blob);

  // Trigger the download
  const link = document.createElement("a");
  link.href = downloadLink;
  link.download = `${type}_Sheet.xlsx`;
  link.click();

  // Clean up the object URL
  URL.revokeObjectURL(downloadLink);
};

//   return <span onClick={downloadXLSX}>Export Survey</span>;
// };

export default downloadFireExtinguisherXLSX;
