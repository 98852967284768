import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button, message, Descriptions, Space, Modal,} from "antd";
import APICall from "../../../services/apiservices";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { PDFDocument, rgb, degrees } from "pdf-lib";
const { confirm } = Modal;

const LicenseCopyDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [licenseCopyDetails, setLicenseCopyDetails] = useState([]);
  const { licenseCopyId } = useParams();
  const todayDate = dayjs();
  const navigate = useNavigate();

  
  const getLicenseCopyDetails = async () => {
    try {
      const response = await APICall(`/licenseCopy/${licenseCopyId}`, {}, 1);
      setLicenseCopyDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch license details");
      setIsLoading(false);
    }
  };

  const loadWatermarkedPreview = async () => {
    try {
      const url =
        "https://fire-safety-bucket-new.s3.ap-south-1.amazonaws.com/LICENCE%20COPY%202023-MFS.pdf";
      const response = await axios.get(url, { responseType: "arraybuffer" });
      const existingPdfBytes = response.data;

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const watermarkText = `Only for ${licenseCopyDetails?.customer?.user?.name}`;
      for (const page of pdfDoc.getPages()) {
        page.drawText(watermarkText, {
          x: 40,
          y: 50,
          size: 20,
          color: rgb(0.8, 0.8, 0.8),
          rotate: degrees(0),
          opacity: 0.4,
        });
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const downloadUrl = URL.createObjectURL(blob);

   
      const previewCard = document.getElementById("preview");
      previewCard.innerHTML = ""; 
      const iframe = document.createElement("iframe");
      iframe.src = downloadUrl;
      iframe.width = "100%";
      iframe.height = "500px";
      previewCard.appendChild(iframe);
    } catch (error) {
      console.error("Error loading watermarked preview:", error);
    }
  };


  const addWatermarkAndDownload = async () => {
    try {
      const url =
        "https://fire-safety-bucket-new.s3.ap-south-1.amazonaws.com/LICENCE%20COPY%202023-MFS.pdf";
      const response = await axios.get(url, { responseType: "arraybuffer" });
      const existingPdfBytes = response.data;

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const watermarkText = `Only for ${licenseCopyDetails?.customer?.user?.name}`;
      for (const page of pdfDoc.getPages()) {
        page.drawText(watermarkText, {
          x: 40,
          y: 50,
          size: 20,
          color: rgb(0.8, 0.8, 0.8),
          rotate: degrees(0),
          opacity: 1,
        });
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const downloadUrl = URL.createObjectURL(blob);

      
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `_License_Copy_${
        licenseCopyDetails?.customer?.user?.name
      }_${todayDate.format("DD-MM-YYYY")}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading watermarked PDF:", error);
    }
  };

  
  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await APICall(`/licenseCopy/${licenseCopyId}`, {}, 4);
        message.success("License Copy deleted successfully!");
        navigate("/licenseCopy");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  useEffect(() => {
    getLicenseCopyDetails();
  }, []);

  useEffect(() => {
    if (licenseCopyDetails?.customer?.user?.name) {
      loadWatermarkedPreview();
    }
  }, [licenseCopyDetails]);

  return (
    <Row>
      <Col span={24}>
        <Card
          title="License Copy Details"
          loading={isLoading}
          extra={
            <Space>
              <Link to="/licenseCopy">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Link to={`/licenseCopy/manage/${licenseCopyId}`}>
                <Button type="primary">Edit</Button>
              </Link>
              <Button
                type="primary"
                onClick={() => {
                  addWatermarkAndDownload().catch((error) => {
                   
                  });
                }}
              >
                Export
              </Button>
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions bordered layout="vertical" style={{ width: "100%" }}>
            <Descriptions.Item label="Customer">
              {licenseCopyDetails?.customer?.user?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Date">
              {dayjs(licenseCopyDetails?.date).format("DD-MM-YYYY")}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card>
          <div id="preview" style={{ height: "500px", overflow: "auto" }}>
            
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default LicenseCopyDetails;
