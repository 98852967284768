import {
  Col,
  Row,
  Button,
  Form,
  Input,
  Card,
  Space,
  Typography,
  message,
  Modal,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import APICall from "../services/apiservices";

const Login = () => {
  const navigate = useNavigate();
  const modalShownRef = useRef(false);
  const [currentCard, setCurrentCard] = useState("login"); // Track which card is displayed
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(60); // Timer for resend button
  const [timerActive, setTimerActive] = useState(false);
  const [transitionStyles, setTransitionStyles] = useState({ opacity: 1, transform: "translateY(0)" });
  const [otp, setOtp] = useState(""); // Store OTP
  const [resetPasswordToken, setResetPasswordToken] = useState(""); // Store token
  const [password, setPassword] = useState(""); // Store new password
  const [confirmPassword, setConfirmPassword] = useState(""); // Store confirm password

  
  useEffect(() => {
    if (!modalShownRef.current) {
      // Check if the user is using Chrome
      const isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor) &&
        !/Edg/.test(navigator.userAgent); // Exclude Edge which is Chromium-based

      if (!isChrome) {
        Modal.warning({
          title: "Browser Compatibility",
          content: "Please use Chrome latest version to ensure functionality of all features.",
          centered: true,
        });
        modalShownRef.current = true; // Set the flag to true to prevent further popups
      }
    }
    if (localStorage.getItem("accessToken")) {
      navigate("/dashboard");
    }
  });

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else if (timer === 0) {
      setTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);

  const animateCardChange = (newCard) => {
    // Animate out
    setTransitionStyles({ opacity: 0, transform: "translateY(-10px)" });
    setTimeout(() => {
      setCurrentCard(newCard); // Change the card after the animation out
      setTransitionStyles({ opacity: 1, transform: "translateY(0)" }); // Animate in
    }, 300);
  };

  const onFinish = async (formData) => {
    try {
      const response = await APICall("/auth/login", formData, 2, false);
      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        window.location.reload();
        setInterval(() => {
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      message.error("Invalid email or password");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleForgotPassword = () => animateCardChange("forgotPassword");

  const handleGetCode = async () => {
    if (!email) {
      message.error("Please enter your email address.");
      return;
    }
  
    try {
      const response = await APICall("/auth/forgotPassword/sendOTP", {email}, 2);
      setIsCodeSent(true);
      setTimer(60);
      setTimerActive(true);
      message.success(response.data.message || "OTP sent!!");
    } catch (error) {
      console.log(error)
      message.error(error.response.data?.message || "Failed to send OTP. Please try again.");
    }
  };  

  const handleVerifyCode = async () => {
    if (!otp) {
      message.error("Please enter the OTP.");
      return;
    }
  
    try {
      const response = await APICall("/auth/forgotPassword/verifyOTP", { email, otp }, 2);
      setResetPasswordToken(response.data.token); // Save token
      message.success("OTP verified!!");
      animateCardChange("resetPassword");
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to verify OTP. Please try again.");
    }
  };

  const handleCaptchaSuccess = () => setIsCaptchaVerified(true);

  const handleUpdatePassword = async () => {
    if (!password) {
      message.error("Please enter a new password.");
      return;
    }
  
    if (password !== confirmPassword) {
      message.error("Passwords do not match.");
      return;
    }
  
    try {
      await APICall("/auth/forgotPassword/resetPassword", {
        email,
        resetPasswordToken,
        password,
      }, 2);
      message.success("Password updated successfully.");
      animateCardChange("login");
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to reset password. Please try again.");
    }
  };  

  return (
    <div style={{ 
      backgroundImage: `url(${process.env.PUBLIC_URL + '/login.png'})`, 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      height: '100vh' 
    }}>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={8}>
          <Row justify="center" style={{ marginBottom: "3rem" }}>
            <Space>
              <img src="ffsslogo.png" alt="" />
              <Typography.Title level={3}>
                Fire Fight Safety Solutions
              </Typography.Title>
            </Space>
            <Typography.Paragraph>
              We aim to save lives, places, belongings, and emotional trauma fire can cause!
            </Typography.Paragraph>
          </Row>

          <div style={{ transition: "all 300ms ease", ...transitionStyles }}>
            {/* Login Card */}
            {currentCard === "login" && (
              <Card title="Login" style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}>
                <Form name="login" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[{ required: true, message: "Please input your email address!" }]}
                  >
                    <Input type="email" placeholder="e.g. rajiv@ffss.in" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                  >
                    <Input.Password placeholder="Enter your password" />
                  </Form.Item>

                  <Row gutter={8} justify={"space-between"}>
                    <Form.Item>
                      <a onClick={(e) => { e.preventDefault(); handleForgotPassword(); }}>
                        Forgot Password?
                      </a>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                        Login
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Card>
            )}

            {/* Forgot Password Card */}
            {currentCard === "forgotPassword" && (
              <Card title="Forgot Password" style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}>
                <Form layout="vertical">
                  <Form.Item label="Email Address" required>
                    <Input
                      type="email"
                      value={email}
                      disabled={isCodeSent}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label="Captcha" required>
                    <Input
                      placeholder="Enter captcha"
                      onChange={handleCaptchaSuccess} // Placeholder for actual captcha logic
                    />
                  </Form.Item>

                  {isCodeSent && (
                    <div style={{ transition: "opacity 300ms ease", opacity: 1 }}>
                      <Form.Item label="Verification Code">
                        <Input placeholder="Enter code" 
                        value={otp} 
                        onChange={(e) => setOtp(e.target.value)} />
                      </Form.Item>
                    </div>
                  )}

                  <Row justify="space-between">
                  {isCodeSent && (
                    <Row justify="space-between">
                      {timerActive ? (
                        <Typography.Text type="secondary">Resend code in {timer}s</Typography.Text>
                      ) : (
                        <Typography.Link onClick={handleGetCode}>Resend Code</Typography.Link>
                      )}
                    </Row>
                  )}
                    {isCodeSent ? (
                      <Button type="primary" disabled={!isCaptchaVerified} onClick={handleVerifyCode}>
                        Verify
                      </Button>
                    ) : (
                      <Col style={{ marginLeft: 'auto' }}> 
                        {!isCodeSent && (
                          <Button type="primary" disabled={!isCaptchaVerified} onClick={handleGetCode}>
                            Get OTP
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                </Form>
              </Card>
            )}

            {/* Reset Password Card */}
            {currentCard === "resetPassword" && (
              <Card title="Reset Password" style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}>
                <Form layout="vertical">
                  <Form.Item label="New Password" required>
                    <Input.Password placeholder="Enter new password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)}/>
                  </Form.Item>
                  <Form.Item label="Verify Password" required
                  validateStatus={confirmPassword && confirmPassword !== password ? "error" : ""}
                  help={confirmPassword && confirmPassword !== password ? "Passwords do not match" : ""}>
                    <Input.Password placeholder="Confirm new password" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)}/>
                  </Form.Item>
                  <Button type="primary" onClick={handleUpdatePassword} style={{ float: "right" }}>
                    Update Password
                  </Button>
                </Form>
              </Card>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
