import { Button, Card, Col, Row, Table, Input, Space } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import APICall from "../../services/apiservices";
import moment from "moment";
const { Search } = Input;

const TrainingCertificateList = () => {
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getReports = async (page, limit, searchTerm) => {
    let url = `/report?type=Training Certificate&page=${page}&limit=${limit}`
    if(searchTerm) url += `&query=${searchTerm}`
    const res = await APICall(url, {}, 1);
    return res.data;
    // setReportData(res.data.documents);
    // setIsLoading(false);
  };

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    const response = await getReports(page, limit, searchTerm);

    setReportData(response.documents);
    console.log(response.documents);
    setIsLoading(false);

    setTableParams(prev => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: response.total,
        current: page, 
      },
    }));
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setReportData([]);
    }
  };

  const onSearch = (value) => {
    console.log(value);
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...tableParams.pagination,
        current: 1, 
      },
    });
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   getReports();
  // }, []);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => (tableParams.pagination.current - 1) * tableParams.pagination.pageSize + index + 1,
    },
    {
      title: "Report Code",
      key: "reportCode",
      render: (_, record) => "FFSS/" + record?.reportCode,
    },
    {
      title: "Customer",
      key: "customer",
      render: (_, record) => record?.customer?.user?.name,
    },
    {
      title: "Issuer",
      key: "issuer",
      render: (_, record) => record?.issuer?.user?.name,
    },

    {
      title: "Issued  Date",
      key: "date",
      render: (_, record) => moment(record?.date).format("DD/MM/YYYY"),
    },
    {
      title: "In Presence Of",
      key: "inPresenceOf",
      render: (_, record) => record?.extraInformation?.inPresenceOf,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Link to={`/view/trainingCertificate/${record._id}`}>
          <Button type="primary">View Report</Button>
        </Link>
      ),
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Card
          title="Training Certificate Reports"
          extra={
            <Space>
              <Search
                style={{
                  width: 220,
                }}
                placeholder="Search a customer"
                allowClear
                enterButton
                size="middle"
                onSearch={onSearch}
              />
              <Link to="/generatereport/trainingCertificate">
                <Button type="primary">Create Report</Button>
              </Link>
            </Space>
          }
          loading={isLoading}
        >
          <Table
            columns={columns}
            dataSource={reportData}
            onChange={handleTableChange}
            pagination={{
              ...tableParams.pagination,
            }}
            // pagination={{
            //   defaultPageSize: 10,
            //   current: currentPage,
            //   onChange: (page) => {
            //     setCurrentPage(page);
            //   },
            // }}
            rowKey={(record) => record.id}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TrainingCertificateList;
