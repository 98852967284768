import React, { useEffect, useState } from "react";
import { GoInfo } from "react-icons/go";
import { BiSolidUpArrow } from "react-icons/bi";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import { FiUsers } from "react-icons/fi";
import { LuCircleDollarSign } from "react-icons/lu";
import { FaRegFileAlt } from "react-icons/fa";
import { RiSurveyLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { Card, Col, Row, Space, Statistic, Table, Typography, Descriptions, Image, Button, message, Progress,} from "antd";
import { Link, useNavigate } from "react-router-dom";
import APICall from "../../services/apiservices";
import CountUp from "react-countup";
import moment from "moment";

const Dashboard = () => {
  const [visible, setVisible] = useState(false);
  const [statsData, setStatsData] = useState();
  const [organizationData, setorganizationData] = useState();
  const [img, setImg] = useState();
  const [loading, setIsLoading] = useState(false);
  const showImage = (img) => {
    setImg(img);
    setVisible(true);
  };

  const [pageSize, setPageSize] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const navaigate = useNavigate();

  const formatter = (value) => <CountUp end={value} separator="," />;

  const getAdminStats = async () => {
    setIsLoading(true);
    try {
      const response = await APICall("/admin/stats", {}, 1);
      setStatsData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.response.data.message);
    }
  };

  const getorganizationData = async () => {
    try {
      const res = await APICall(`/organization/649bff0f87a35d7bececa3d8`, {}, 1);
      setorganizationData(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAdminStats();
    getorganizationData();
  }, []);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_, record, index) => {
        return (currentPage - 1) * pageSize + index + 1;
      },
    },
    {
      title: "Customer",
      key: "name",
      render: (record) => record?.amcId?.customerId?.userId?.name,
    },
    {
      title: "Employee",
      key: "employee",
      render: (record) => record?.assignedTo?.userId?.name || "Not Assigned",
    },
    {
      title: "Scheduled Date",
      key: "date",
      render: (record) => moment(record?.scheduledDate).format("DD/MM/YYYY"),
    },
  ];

  return (
    <Space direction="vertical" size={20}>
      <Row gutter={16}>
        <Col className="gutter-row" span={4}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/employees");
            }}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <FiUsers style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Employees</Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={statsData?.employees?.employeesCount}
            />
            <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>
            {statsData?.employees?.employeesCountInPastMonth || 0} joined this month
            </Typography.Text>
          </Card>
        </Col>

        <Col className="gutter-row" span={4}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/surveys");
            }}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <RiSurveyLine style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Surveys</Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={statsData?.surveys?.upcomingSurveysCount}
            />

            <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>
              {statsData?.surveys?.upcomingSurveysCount || 0} upcoming
            </Typography.Text>
          </Card>
        </Col>

        <Col className="gutter-row" span={4}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/customers");
            }}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={<LuUser style={{ fontSize: "20px", color: "#024fa1" }} />}
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Customers</Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={statsData?.customers?.customersCount}
            />
            <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>
              {statsData?.customers?.customersCountInPastMonth} added recently
            </Typography.Text>

            {/* <Typography.Text>
                This month sales {data.customers.thisMonthSales}
              </Typography.Text> */}
          </Card>
        </Col>

        <Col className="gutter-row" span={4}>
          <Card
            bordered={false}
            onClick={() => {
              navaigate("/items");
            }}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <MdOutlineProductionQuantityLimits
                  style={{ fontSize: "19px", color: "#024fa1" }}
                />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Items</Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={statsData?.items?.itemsCount}
            />
            <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>{statsData?.items?.itemsCountInPastMonth} created recently</Typography.Text>
          </Card>
        </Col>

        <Col className="gutter-row" span={4}>
          <Card
            bordered={false}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <FaRegFileAlt
                  style={{ fontSize: "19px", color: "#024fa1" }}
                />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Form A and B</Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={(statsData?.formACount || 0) + (statsData?.formBCount || 0)}
            />
            <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>A: {statsData?.formACount || 0} | B: {statsData?.formBCount || 0} </Typography.Text>
          </Card>
        </Col>

        <Col className="gutter-row" span={4}>
          <Card
            bordered={false}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <LuCircleDollarSign
                  style={{ fontSize: "19px", color: "#024fa1" }}
                />
              }
              precision={2}
              formatter={formatter}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Purchase Order</Col>
                    <Col>
                      <GoInfo style={{ fontSize: "20px" }} />
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={statsData?.po?.count || 0}
            />
            <Progress percent={99.99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>{statsData?.po?.countInThisMonth || 0} added this month</Typography.Text>
          </Card>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <Card
            title="AMC & AMC Visits"
            extra={
              <Link to="/amcs">
                <Button type="primary" ghost>
                  View All
                </Button>
              </Link>
            }
            loading={loading}
          >
            <Row gutter={10}>
              <Col className="gutter-row" span={12}>
                <Card bordered={false}>
                  <Statistic
                    precision={2}
                    formatter={formatter}
                    title={
                      <>
                        <Row gutter={9}>
                          <Col>AMC</Col>
                          <Col>
                            <GoInfo style={{ fontSize: "20px" }} />
                          </Col>
                        </Row>
                      </>
                    }
                    valueStyle={{ fontWeight: 500 }}
                    value={statsData?.amc?.amcCount}
                    suffix={
                      <Row style={{ marginLeft: "1rem" }}>
                        <Space size={3}>
                          <>{12}</>
                          <BiSolidUpArrow
                            style={{
                              marginTop: "6px",
                              color: "#52C41A",
                              fontSize: "15px",
                            }}
                          />
                        </Space>
                      </Row>
                    }
                  />
                  Chart
                </Card>
              </Col>
              <Col className="gutter-row" span={12}>
                <Card bordered={false}>
                  <Statistic
                    precision={2}
                    formatter={formatter}
                    title={
                      <>
                        <Row gutter={9}>
                          <Col>AMC Visits</Col>
                          <Col>
                            <GoInfo style={{ fontSize: "20px" }} />
                          </Col>
                        </Row>
                      </>
                    }
                    valueStyle={{ fontWeight: 500 }}
                    value={statsData?.visits?.upcomingVisitsCount}
                    suffix={
                      <Row style={{ marginLeft: "1rem" }}>
                        <Space size={3}>
                          <>{12}</>
                          <BiSolidUpArrow
                            style={{
                              marginTop: "6px",
                              color: "#52C41A",
                              fontSize: "15px",
                            }}
                          />
                        </Space>
                      </Row>
                    }
                  />
                  Chart
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              <Col className="gutter-row" span={24}>
                <Table
                  columns={columns}
                  dataSource={statsData?.visits?.upcomingVisits}
                  pagination={{
                    defaultPageSize: pageSize,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  rowKey={(record) => record.id}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={loading}>
            <Descriptions
              title="About organization"
              size="small"
              layout="vertical"
              bordered
              extra={
                <Link to="/settings">
                  <Button type="primary" ghost>
                    Edit
                  </Button>
                </Link>
              }
            >
              <Descriptions.Item label="organization">
                {organizationData?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile">
                {organizationData?.mobile}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {organizationData?.email}
              </Descriptions.Item>
              <Descriptions.Item label="License Number">
                {organizationData?.licenceNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Active Finance Year" span={2}>
                {organizationData?.financialYear}
              </Descriptions.Item>
              <Descriptions.Item label="Website">
                <a href={`${organizationData?.website}`}>
                  <Button type="link" style={{ padding: 0 }}>
                    {organizationData?.website}
                  </Button>
                  <LiaExternalLinkAltSolid />
                </a>
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={2}>
                {organizationData?.address}
              </Descriptions.Item>
              <Descriptions.Item label="organization Logo">
                <Button
                  type="link"
                  onClick={() => {
                    showImage("ffsslogo.png");
                  }}
                  style={{ padding: 0 }}
                >
                  <Image
                    src="ffsslogo.png"
                    style={{ height: "20px", width: "auto" }}
                    preview={false}
                  />
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="Letterhead Header (Default)">
                <Button
                  type="link"
                  onClick={() => {
                    showImage(
                      organizationData?.letterheads?.letterheadHeaders[0].url
                    );
                  }}
                  style={{ padding: 0 }}
                >
                  <Image
                    src={organizationData?.letterheads?.letterheadHeaders[0].url}
                    style={{ height: "20px", width: "auto" }}
                    preview={false}
                  />
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="Letterhead Footer (Default)">
                <Button
                  type="link"
                  onClick={() => {
                    showImage(
                      organizationData?.letterheads?.letterheadFooters[0].url
                    );
                  }}
                  style={{ padding: 0 }}
                >
                  <Image
                    src={organizationData?.letterheads?.letterheadFooters[0].url}
                    style={{ height: "20px", width: "auto" }}
                    preview={false}
                  />
                </Button>
              </Descriptions.Item>
            </Descriptions>
            <Image
              preview={{
                visible,
                src: img,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default Dashboard;
