import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  message,
  Spin,
  Input,
  Space,
  Tooltip,
  Tag,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import APICall from "../../services/apiservices";

const { Search } = Input;

const Employees = ({ isActive }) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getEmployees = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const url =
        isActive === true
          ? `/employee?page=${page}&limit=${limit}&isActive=${isActive}&query=${searchTerm}`
          : `/employee?page=${page}&limit=${limit}&isInactive=${true}&query=${searchTerm}`;
      const response = await APICall(url, {}, 1);
      return response.data;
      // setEmployees(response.data.documents);
      // setIsLoading(false);
    } catch (error) {
      message.error("failed to get employee data");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getEmployees(1, 10);
  // }, [reload]);

  const fetchList = async () => {
    // setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getEmployees(searchTerm, page, limit);
    setEmployees(response?.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        page: page,
        total: response?.total,
        limit: limit,
      },
    });
    console.log(tableParams);
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), isActive]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setEmployees([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
      responsive: ["md"],
    },
    {
      title: "Emp. Code",
      key: "empCode",
      render: (_, record) => {
        return (
          <Link to={`/employees/${record._id}`}>
            FFSS/EMP/{record.employeeCode.padStart(4, 0)}
          </Link>
        );
      },
      responsive: ["md"],
      width: 120,
    },
    {
      title: "Name",
      key: "empName",
      render: (_, record) => {
        return <Link to={`/employees/${record._id}`}>{record.user.name}</Link>;
      },
      responsive: ["md"],
      width: 120,
    },
    {
      title: "Access Type",
      dataIndex: "accessType",
      key: "accessType",
      render: (_, record) => (
        <Tag
          color={
            (record.accessType === "Super Admin" && "green") ||
            (record.accessType === "General Employee" && "blue")
          }
        >
          {record.accessType}
        </Tag>
      ),
      width: 120,
    },

    {
      title: "Email",
      key: "email",
      render: (_, record) => {
        return <a href={`mailto:${record.user.email}`}>{record.user.email}</a>;
      },

      responsive: ["lg"],
      width: 150,
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (_, record) => {
        return (
          <a href={`tel:+91${record.user.mobile}`}>{record.user.mobile}</a>
        );
      },
    },
    {
      title: "Visits",
      dataIndex: "visitCount",
      key: "visits",
    },
    {
      title: "Surveys",
      dataIndex: "surveyCount",
      key: "surveys",
    },
    {
      title: () => {
        return (
          // <Row align="middle">
          //   Attendence
          //   <Tooltip title="Attendence of the current month">
          //     <LiaInfoCircleSolid
          //       style={{
          //         margin: "0px 0px 0px 10px",
          //         color: "red",
          //         fontSize: "18px",
          //       }}
          //     />
          //   </Tooltip>
          // </Row>
          <Tooltip title="Attendence of the current month">Attendance</Tooltip>
        );
      },
      // title: "Attendance (Last Month)",
      dataIndex: "attendanceCount",
      key: "attendance",
    },
    {
      title: "Access Status",
      key: "accessStatus",
      render: (_, record) => (
        <Tag color={record.user.isActive ? "green" : "red"}>
          {record.user.isActive === true ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/employees/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/employees/manage/${record._id}`}>Edit</Link>,
        //       },
        //       {
        //         key: 3,
        //         label: "Create Offer Letter",
        //         onClick: () => {
        //           navigate(`/employees/${record._id}/offerLetter`);
        //         },
        //       },

        //       {
        //         key: 4,
        //         label: "Reset Password",
        //         onClick: () => {
        //           handleResetpassword(record);
        //         },
        //       },
        //       {
        //         key: 5,
        //         label: "Send Password via Email",
        //         onClick: () => {
        //           handleSendPassword(record);
        //         },
        //       },
        //       {
        //         key: 6,
        //         label: isActive ? "Disable" : "Enable",
        //         onClick: () => {
        //           handleDeactivate(record);
        //         },
        //         danger: isActive ? true : false,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Button
          type="primary"
          onClick={() => navigate(`/employees/${record._id}`)}
          ghost
        >
          View
        </Button>
      ),
      align: "center",
      fixed: "right",
    },
  ];
  return (
    <>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Card
              title={isActive ? "Employee Management" : "Inactive Employees"}
              extra={
                <Row
                  justify={"end"}
                  align="top"
                  style={{ paddingBottom: "10px" }}
                >
                  <Space>
                    <Search
                      style={{
                        width: 220,
                      }}
                      placeholder="Search an employee"
                      allowClear
                      enterButton
                      // loading={loading}
                      size="middle"
                      onSearch={onSearch}
                    />
                    {isActive && (
                      <Link to="/employees/manage">
                        <Button
                          type="primary"
                          icon={<LuPlus style={{ paddingTop: "2px" }} />}
                        >
                          Create Employee
                        </Button>
                      </Link>
                    )}
                  </Space>
                </Row>
              }
            >
              <Table
                isLoading={isLoading}
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={employees}
                size="small"
                onChange={handleTableChange}
                pagination={{
                  ...tableParams.pagination,
                }}
                scroll={{ x: "1000px" }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Employees;
