import React from "react";
import AppRoutes from "./routes/AppRoutes";
import AuthProvider from "./auth/AuthProvider";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
