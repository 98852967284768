import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Dropdown,
  Table,
  Modal,
  Radio,
  Upload,
} from "antd";
import dayjs from "dayjs";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Select } from "antd";
import { LuPlus } from "react-icons/lu";
import moment from "moment";
import axios from "axios";
const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ManagePurchaseOrder = ({ isEdit }) => {
  const [poData, setPOData] = useState();
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = useState(undefined);
  const [employeeValue, setEmployeeValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [attachmentFileId, setAttachmentFileId] = useState();
  const [systems, setSystems] = useState([]);

  const getPODetails = async () => {
    try {
      const response = await APICall(`/purchaseOrder/${params.id}`, {}, 1);
      setPOData(response.data);
      setAttachmentFileId(response.data.file._id)
      form.setFieldsValue({
        customerId: {
          label: response.data.customer.user.name,
          value: response.data.customer._id,
        },
        amount: response.data.amount,
        poNumber: response.data.poNumber,
        poDate: dayjs(new Date(response.data.poDate)),
        systemTypes: response.data.systems.map((st) => {
          return {
            value: st,
            label: st,
          };
        }),
        timeline: response.data.timeline,
        file: response.data.file
      });
      setIsLoading(false);
    } catch (error) {
      message.error('Failed getting data');
      setIsLoading(false);
    }
  };

  const getSystems = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/systemType`,
        {},
        1
      );
      setSystems(response.data);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getSystems();
    if (isEdit) {
      setIsLoading(true);
      getPODetails();
    }
  }, []);

  const onFinish = async (values) => {
    setIsLoading(!isLoading);
    if (isEdit) {
      const data = { ...values };
      delete data.systemTypes
      data.fileId = attachmentFileId;
      delete data.file;
      data.customerId = typeof(values.customerId) === "string" ? values.customerId : values.customerId.value
      const response = await APICall(`/purchaseOrder/${params.id}`, data, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("Purchase order has been updated successfully.");
        navigate("/purchaseOrder");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const data = { ...values };
        data.systems = data.systemTypes.map((st) => st)
        delete data.systemTypes
        data.fileId = attachmentFileId;
        delete data.file;
        const response = await APICall(`/purchaseOrder`, data, 2);
        setIsLoading(false);
        if (response.data) {
          message.success("PO has been created successfully.");
          navigate(`/purchaseOrder`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
      data: customer,
    }));
  };

  const handleFileUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Setting",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setAttachmentFileId(fileResponse.data.data[0].id);
    return false;
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Card title={params.id ? 'Edit PO' : "Create PO"}>
            <Form
              form={form}
              layout="vertical"
              // wrapperCol={{
              //     span: 20,
              // }}
              onFinish={onFinish}
              initialValues={{ includeGST: true }}
            >
              <Row gutter={16}>
                <Col span={24}>
                    <Row gutter={10}>
                      <Col span={8}>
                        <Form.Item
                          name="customerId"
                          label="Select Customer"
                          rules={[
                            {
                              required: true,
                              message: "Please select the customer",
                            },
                          ]}
                        >
                          <DebounceSelect
                            value={value}
                            placeholder="e.g. John Doe"
                            fetchOptions={fetchCustomerList}
                            onChange={(newValue, data) => {
                              setValue(newValue);
                              setSelectedCustomer(data.data);
                              form.setFieldValue("contactPersonId", undefined);
                            }}
                            style={{ width: "100%" }}
                            tabIndex={1}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="PO Number"
                          name="poNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please enter PO number",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 874" />
                        </Item>
                      </Col>
                      
                      <Col span={8}>
                        <Item
                          label="Amount"
                          name="amount"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Amount",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 18000" type="number" />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Date"
                          name="poDate"
                          rules={[
                            {
                              required: true,
                              message: "Please select date",
                            },
                          ]}
                        >
                          <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name="systemTypes"
                          label="Select Systems"
                          rules={[
                            {
                              required: true,
                              message: "Please select the systems",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            disabled={params.id} 
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Please select"
                            // onChange={handleChange}
                            options={systems?.map((s) => ({
                              label: s.name,
                              value: s.name,
                            }))}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Timeline (in months)"
                          name="timeline"
                          rules={[
                            {
                              required: true,
                              message: "Please enter number of months",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 12" type="number" />
                        </Item>
                      </Col>

                      <Col span={16}>
                        <Item
                          label="Attachment File"
                          name="file"
                          rules={[
                            {
                              required: true,
                              message: "Please upload a file",
                            },
                          ]}
                        >
                          <Upload
                            Upload
                            name="attachment"
                            beforeUpload={handleFileUpload}
                            multiple={false}
                          >
                            <Button>Click to Upload</Button>
                          </Upload>
                        </Item>
                      </Col>

                      <Col span={24}>
                        <Item>
                          <Button type="primary" htmlType="submit" tabIndex={8}>
                            {isEdit ? "Save" : "Create"}
                          </Button>
                        </Item>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManagePurchaseOrder;
