import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  message,
  Descriptions,
  Space,
  Modal,
} from "antd";
import APICall from "../../../services/apiservices";
import { Link, useNavigate, useParams } from "react-router-dom";
const { confirm } = Modal;

const CashLedgerDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [cashLedgerDetails, setCashLedgerDetails] = useState([]);
  const { cashLedgerId } = useParams();
  const navigate = useNavigate();

  const getCashLedgerDetails = async () => {
    try {
      const response = await APICall(`/cashLedger/${cashLedgerId}`, {}, 1);
      setCashLedgerDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch cash ledger details");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCashLedgerDetails();
  });

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await APICall(`/cashLedger/${cashLedgerId}`, {}, 4);
        message.success("Cash Ledger deleted successfully!");
        navigate("/cashLedger");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Cash Ledger Details"
          loading={isLoading}
          extra={
            <Space>
              <Link to="/cashLedger">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Link to={`/cashLedger/manage/${cashLedgerId}`}>
                <Button type="primary">Edit</Button>
              </Link>
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions bordered layout="vertical" style={{ width: "100%" }}>
            <Descriptions.Item label="Type">
              {cashLedgerDetails?.type}
            </Descriptions.Item>
            <Descriptions.Item label="Client Name">
              {cashLedgerDetails?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {cashLedgerDetails?.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Service">
              {cashLedgerDetails?.service}
            </Descriptions.Item>
            <Descriptions.Item label="Mode of Payment">
              {cashLedgerDetails?.modeOfPayment}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {cashLedgerDetails?.Descriptions}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

export default CashLedgerDetails;
