import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
// import PrintUtility from "../../utils/Print";
import {
  Row,
  Card,
  Col,
  Table,
  Space,
  Button,
  message,
  Modal,
  Dropdown,
  Input,
} from "antd";

import { Link } from "react-router-dom";
import APICall from "../../services/apiservices";
import moment from "moment";
const { confirm } = Modal;
const { Search } = Input;

const AMCs = () => {
  const [print, setPrint] = useState();
  const [amcs, setAmcs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  // useEffect(() => {
  //   getAMCs();
  // }, [])

  const fetchList = async () => {
    // setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getAMCs(searchTerm, page, limit);
    //console.log(response)
    // console.log(response)
    setAmcs(response.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const getAMCs = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/amc?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      return response.data;
    } catch (error) {
      message.error("failed to get employee data");
      setIsLoading(false);
    }
  };

  const handleDelete = async (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/amc/${record._id}`, {}, 4);
        fetchList();
        message.success("AMC deleted successfully!");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const handlePrint = (record) => {
    setPrint(record);
  };

  // Table columns
  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "AMC Number",
      key: "amcNo",
      render: (record) => {
        return "FFSS/AMC" + record.amcNo.toString().padStart(4, "0");
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Customer",
      dataIndex: ["customer", "user", "name"],
      key: "customer",
    },
    {
      title: "Duration (Months)",
      dataIndex: "duration",
      key: "duration",
    },

    {
      title: "Total Visits",
      dataIndex: "visitsCount",
      key: "visitsCount",
    },
    {
      title: "Contract Date",
      dataIndex: "contractDate",
      key: "contractDate",
      render: (_, record) => moment(record.contractDate).format("DD/MM/YYYY"),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (_, record) => moment(record.expiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/amcs/details/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/amcs/manage/${record._id}`}>Edit</Link>,
        //       },
        //       {
        //         key: 3,
        //         label: <Link to={`/amcs/${record._id}`}>Export</Link>,
        //       },
        //       {
        //         key: 4,
        //         label: (
        //           <span
        //             style={{ color: "red" }}
        //             onClick={() => handleDelete(record)}
        //           >
        //             Delete
        //           </span>
        //         ),
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>

        <Link to={`/amcs/details/${record._id}`}>
          <Button type="primary" ghost>
            View
          </Button>
        </Link>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setAmcs([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="AMC Management"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search by customer"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/amcs/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create AMC
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            dataSource={amcs}
            columns={columns}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
            size="small"
          />
        </Card>
      </Col>
      {/* {print && (
        <Modal open={print} onCancel={() => setPrint(null)} width={"100%"}>
          <PrintUtility data={print} />
        </Modal>
      )} */}
    </Row>
  );
};

export default AMCs;
