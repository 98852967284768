import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Col,
  Row,
  message,
  Spin,
  Radio,
  Select,
  Table,
  Dropdown,
  Modal,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";

const { confirm } = Modal;

const ManageCustomer = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  // const [customerData, setCustomerData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [contactPersonForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactPersons, setContactPersons] = useState([]);
  // const [contactPerson, setContactPerson] = useState();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      if (await contactPersonForm.validateFields()) {
        const formData = contactPersonForm.getFieldsValue();
        if (
          !contactPersons.find(
            (cp) => cp.mobile === formData.mobile || cp.email === formData.email
          )
        ) {
          const tempContactPersons = [...contactPersons];
          setContactPersons([...tempContactPersons, formData]);
          contactPersonForm.resetFields();
          setIsModalOpen(false);
        } else {
          message.error(
            "You already added a contact person with same email or mobile"
          );
          return;
        }
      }
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getCustomerDetails = async () => {
    try {
      const response = await APICall(`/customer/${customerId}`, {}, 1);
      // setCustomerData(response.data);
      // setContactPerson(response.data.contactPersons);
      const customerData = response.data;
      const initialValues = {
        name: customerData?.user?.name,
        email: customerData?.user?.email,
        mobile: customerData?.user?.mobile,
        isActive: customerData?.user?.isActive,
        address: customerData?.user?.address,
        state: customerData?.user?.state,
        pincode: customerData?.user?.pincode,
        gstNumber: customerData?.gstNumber,
      };
      form.setFieldsValue(initialValues);
      setContactPersons(customerData?.contactPersons);
      setIsLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldValue("state", "Maharashtra");
    if (customerId) {
      setIsLoading(true);
      getCustomerDetails();
    }
  }, []);

  const onFinish = async (formData) => {
    setIsLoading(!isLoading);
    if (customerId) {
      formData.contactPersons = [...contactPersons];
      const response = await APICall(`/customer/${customerId}`, formData, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("Customer has been updated successfully.");
        navigate(`/customers/${customerId}`);
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        formData.contactPersons = [...contactPersons];
        const response = await APICall(`/customer`, formData, 2);
        if (response.data) {
          setIsLoading(false);
          message.success("Customer has been created successfully.");
          navigate(`/customers`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "name",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record.name;
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      render: (_, record, index) => {
        return record.mobile;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, record, index) => {
        return record.email;
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      render: (_, record, index) => {
        return record.designation;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: (
                  <span
                    style={{ color: "red" }}
                    onClick={() => {
                      confirm({
                        title: `Remove contact person?`,
                        content: `Do you want to remove ${record.name}?`,
                        okText: "Yes",
                        okType: "danger",
                        cancelText: "No",
                        onOk: () => {
                          const tempContactPersons = [...contactPersons];
                          setContactPersons([
                            ...tempContactPersons.filter(
                              (v) => v.email !== record.email
                            ),
                          ]);
                          message.success("Removed contact person!");
                        },
                        onCancel: () => {
                          // message.info("Delete canceled");
                        },
                      });
                    }}
                  >
                    Remove
                  </span>
                ),
              },
            ],
          }}
          placement="bottom"
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
      align: "center",
    },
  ];
  return (
    <>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Card
              title={
                customerId !== undefined ? "Edit Customer" : "Create Customer"
              }
            >
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{ isActive: true }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Card
                      title="Personal Information"
                      bordered={false}
                      style={{ marginBottom: "20px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: "Customer name is required.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. Suryodaya Sankul"
                              tabIndex={1}
                            />
                          </Form.Item>

                          <Form.Item
                            name="gstNumber"
                            label="GSTIN"
                            rules={[
                              {
                                pattern:
                                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                                message:
                                  "Invalid GSTIN. Please enter a valid GSTIN.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 27AAJCB3919N1ZE"
                              tabIndex={4}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                required: true,
                                message: "Customer mobile number is required.",
                              },
                              {
                                pattern: /^[0-9]{10}$/,
                                message:
                                  "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 9876543210"
                              type="number"
                            />
                          </Form.Item>

                          <Form.Item name="isActive" label="Account Status">
                            <Radio.Group
                              name="isActive"
                              defaultValue={true}
                              tabIndex={5}
                            >
                              <Radio value={true}>Active</Radio>
                              <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Customer email is required.",
                              },
                              {
                                type: "email",
                                message:
                                  "Invalid email address. Please enter a valid email address.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. ram.shah124@gmail.com"
                              tabIndex={3}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      title="Address"
                      bordered={false}
                      style={{ marginBottom: "20px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                              {
                                required: true,
                                message: "Customer address is required.",
                              },
                            ]}
                          >
                            <TextArea
                              rows={3}
                              placeholder="e.g. 4th Floor, Vasani Cottage, Opp. Kalyan Jewellers, S.V. Road, Borivali West, Mumbai - 400092"
                              tabIndex={6}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="pincode"
                            label="Pincode"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Customer address pincode is required.",
                              },
                              {
                                pattern: /^[0-9]{6}$/,
                                message:
                                  "Invalid pincode. Please enter a valid 6-digit pincode.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. 400092" tabIndex={7} />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            name="state"
                            label="State"
                            rules={[
                              {
                                required: true,
                                message: "Customer state is required.",
                              },
                            ]}
                          >
                            <Select
                              defaultValue="Maharshtra"
                              value={"Maharashtra"}
                              style={{
                                width: "100%",
                              }}
                              options={[
                                {
                                  value: "Andaman and Nicobar Islands",
                                  label: "Andaman and Nicobar Islands",
                                },
                                {
                                  value: "Andhra Pradesh",
                                  label: "Andhra Pradesh",
                                },
                                {
                                  value: "Arunachal Pradesh",
                                  label: "Arunachal Pradesh",
                                },
                                { value: "Assam", label: "Assam" },
                                { value: "Bihar", label: "Bihar" },
                                { value: "Chandigarh", label: "Chandigarh" },
                                {
                                  value: "Chhattisgarh",
                                  label: "Chhattisgarh",
                                },
                                {
                                  value: "Dadra and Nagar Haveli",
                                  label: "Dadra and Nagar Haveli",
                                },
                                {
                                  value: "Daman and Diu",
                                  label: "Daman and Diu",
                                },
                                { value: "Delhi", label: "Delhi" },
                                { value: "Goa", label: "Goa" },
                                { value: "Gujarat", label: "Gujarat" },
                                { value: "Haryana", label: "Haryana" },
                                {
                                  value: "Himachal Pradesh",
                                  label: "Himachal Pradesh",
                                },
                                { value: "Jharkhand", label: "Jharkhand" },
                                { value: "Karnataka", label: "Karnataka" },
                                { value: "Kerala", label: "Kerala" },
                                { value: "Lakshadweep", label: "Lakshadweep" },
                                {
                                  value: "Madhya Pradesh",
                                  label: "Madhya Pradesh",
                                },
                                { value: "Maharashtra", label: "Maharashtra" },
                                { value: "Manipur", label: "Manipur" },
                                { value: "Meghalaya", label: "Meghalaya" },
                                { value: "Mizoram", label: "Mizoram" },
                                { value: "Nagaland", label: "Nagaland" },
                                { value: "Odisha", label: "Odisha" },
                                { value: "Puducherry", label: "Puducherry" },
                                { value: "Punjab", label: "Punjab" },
                                { value: "Rajasthan", label: "Rajasthan" },
                                { value: "Sikkim", label: "Sikkim" },
                                { value: "Tamil Nadu", label: "Tamil Nadu" },
                                { value: "Telangana", label: "Telangana" },
                                { value: "Tripura", label: "Tripura" },
                                {
                                  value: "Uttar Pradesh",
                                  label: "Uttar Pradesh",
                                },
                                { value: "Uttarakhand", label: "Uttarakhand" },
                                { value: "West Bengal", label: "West Bengal" },
                              ]}
                              tabIndex={8}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Card
                  title="Contact Persons"
                  extra={
                    <>
                      <Button
                        type="primary"
                        onClick={showModal}
                        tabIndex={7}
                        ghost
                      >
                        Add Contact Person
                      </Button>
                    </>
                  }
                >
                  <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={contactPersons}
                    size="large"
                  />
                </Card>

                <Form.Item style={{ marginTop: "20px" }}>
                  <Button type="primary" htmlType="submit">
                    {customerId !== undefined ? "Save" : "Create"}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      <Form
        form={contactPersonForm}
        layout="vertical"
        onFinish={(value) => console.log(value)}
      >
        <Modal
          title="Add Contact Person"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ type: "primary", htmlType: "submit" }}
          centered
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Contact person's name is required.",
                  },
                ]}
              >
                <Input placeholder="e.g. Rajat Patil" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Contact person's mobile number is required.",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message:
                      "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                  },
                ]}
              >
                <Input
                  tabIndex={2}
                  placeholder="e.g. 9876543210"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Contact person's email is required.",
                  },
                  {
                    type: "email",
                    message:
                      "Invalid email address. Please enter a valid email address.",
                  },
                ]}
              >
                <Input placeholder="e.g. rajat.patil@gmail.com" tabIndex={3} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="designation" label="Designation">
                <Input placeholder="e.g. Secretary" tabIndex={3} />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default ManageCustomer;
