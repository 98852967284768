import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Form,
  DatePicker,
  Input,
  Checkbox,
  Space,
  Button,
  Card,
  message,
  Modal,
} from "antd";
import APICall from "../../../services/apiservices";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import moment from "moment";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { PDFDocument, handleDownloadPDF } from "../../../pdfs/pdfService";
import InstallationCertificate from "../../../pdfs/installationCertificate";
import { useParams, useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";

const { confirm } = Modal;

const InstallationCertificateForm = ({ view, edit }) => {
  const [customer, setCustomer] = useState(undefined);
  const [item, setItem] = useState(undefined);
  const [reportForm] = Form.useForm();
  const [reportData, setReportData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [reportId, setReportId] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  const fetchItemList = async (searchValue) => {
    const res = await APICall(
      `/item?page=1&limit=30&name=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((item) => ({
      label: `${item.name + "-" + item.description}`,
      value: item._id,
    }));
  };

  function disabledDate(current) {
    const now = moment();
    return current && current < now.startOf("day");
  }

  const generateReport = async (formData) => {
    setIsLoading(true);
    try {
      const dataTosend = {
        organizationId: "649bff0f87a35d7bececa3d8",
        ...formData,
        type: "Installation Certificate",
      };

      let res;
      if (!view) {
        res = await APICall("/report", dataTosend, 2);
        setReportId(res.data.data._id);
      }
      const res2 = await APICall(
        `/report/${view ? params.id : res.data.data._id}`,
        {},
        1
      );
      // const res3 = await APICall(`/organization/${res2.data.organizationId}`, {}, 1);

      const installedItemList = res2.data.items.map((item) => {
        return {
          name: item.item.name,
          quantity: item.quantity,
          description: item.otherDetails === "-" ? "" : item.otherDetails,
        };
      });

      let letterhead = "";
      let letterfooter = "";
      if (view) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      } else if (formData.includeLetterheadHeader) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      }
      console.log(installedItemList);
      setReportData({
        letterheader: letterhead,
        letterfooter: letterfooter,
        headingDate: moment(res2?.data?.date).format("DD/MM/YYYY"),
        customer: {
          name: res2?.data?.customer?.user?.name,
          address: res2?.data?.customer?.user?.address,
        },
        data: installedItemList,
      });
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const updateReport = async (formData) => {
    setIsLoading(true);
    const customerId = formData.customerId.value ?? formData.customerId;
    delete formData.customerId;

    formData.items = formData.items.map((item) => {
      console.log(item);
      return {
        itemId: item.itemId.value || item.itemId,
        quantity: item.quantity,
        otherDetails: item.otherDetails || "-",
      };
    });
    try {
      const dataTosend = {
        organizationId: "649bff0f87a35d7bececa3d8",
        ...formData,
        customerId: customerId,
        type: "Installation Certificate",
      };

      const res = await APICall(`/report/${params.id}`, dataTosend, 3);
      setReportId(res.data.data._id);

      const res2 = await APICall(`/report/${params.id}`, {}, 1);
      // const res3 = await APICall(`/organization/${res2.data.organizationId}`, {}, 1);

      const installedItemList = res2.data.items.map((item) => {
        return {
          name: item.item.name,
          quantity: item.quantity,
          description: item.otherDetails === "-" ? "" : (item.otherDetails ?? ""),
        };
      });

      let letterhead = "";
      let letterfooter = "";
      if (view) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      } else if (formData.includeLetterheadHeader) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      }

      setReportData({
        letterheader: letterhead,
        letterfooter: letterfooter,
        headingDate: moment(res2?.data?.date).format("DD/MM/YYYY"),
        customer: {
          name: res2?.data?.customer?.user?.name,
          address: res2?.data?.customer?.user?.address,
        },
        data: installedItemList,
      });
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getReportData = async () => {
    setIsLoading(true);
    try {
      const res = await APICall(`/report/${params.id}`, {}, 1);
      console.log(res.data.items);
      reportForm.setFieldsValue({
        customerId: {
          label: res?.data?.customer?.user?.name,
          value: res?.data?.customerId,
        },
        date: dayjs(res?.data?.date),
      });

      reportForm.setFieldsValue({
        items: res.data.items.map((item) => {
          return {
            itemId: {
              label: item.item.name,
              value: item.itemId,
            },
            quantity: item.quantity,
            otherDetails: item.otherDetails,
          };
        }),
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to fetch data");
    }
  };

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete report?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/report/${reportId}`, {}, 4);
        // getQuotations(0, 10);
        message.success("Report deleted successfully!");
        navigate("/reports/installationCertificate");
      },
    });
  };

  useEffect(() => {
    if (view) {
      generateReport();
      setReportId(params.id);
    }
    if (edit) {
      getReportData();
    }
  }, [edit, view]);

  return (
    <>
      <Row gutter={20}>
        <Col span={24}>
          <Card
            title="Installation Report"
            loading={view ? isLoading : false}
          >
            {!view && (
              <Form
                form={reportForm}
                layout="vertical"
                onFinish={edit ? updateReport : generateReport}
                initialValues={{ place: "Pune" }}
              >
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name="customerId"
                      label="Select Customer"
                      rules={[
                        {
                          required: true,
                          message: "Please select the customer",
                        },
                      ]}
                    >
                      <DebounceSelect
                        value={customer}
                        placeholder="Select a user"
                        fetchOptions={fetchCustomerList}
                        onChange={(newCustomer) => {
                          setCustomer(newCustomer);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="date"
                      label="Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select date",
                        },
                      ]}
                    >
                      <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "itemId"]}
                              label="Item"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select item",
                                },
                              ]}
                              style={{ marginLeft: "12px" }}
                            >
                              <DebounceSelect
                                value={item}
                                placeholder="Select Item"
                                fetchOptions={fetchItemList}
                                onChange={(newItem) => {
                                  setItem(newItem);
                                }}
                                style={{ width: "380px" }}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "quantity"]}
                              label="Quantity"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing quantity of item",
                                },
                              ]}
                              style={{ marginLeft: "17px" }}
                            >
                              <Input
                                placeholder="e.g. 1,2,3"
                                type="number"
                                style={{ width: "370px" }}
                                min={1}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "otherDetails"]}
                              label="Description"
                              style={{ marginLeft: "17px" }}
                            >
                              <Input.TextArea
                                placeholder="e.g. Net Weight 1.5kg"
                                style={{ width: "370px" }}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            style={{ marginLeft: "13px", width: "510px" }}
                          >
                            Add Item
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Col span={6}>
                    <Space>
                      <Form.Item
                        name="includeLetterheadHeader"
                        valuePropName="checked"
                      >
                        <Checkbox>Print With Letterhead</Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                          onClick={() => setReportData(undefined)}
                        >
                          {edit ? "Update" : "Create"}
                        </Button>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
            )}

            {reportData && (
              <>
                <Space style={{ float: "right", marginBottom: "20px" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setReportData(undefined);
                      if (view) {
                        navigate(-1);
                      }
                    }}
                    ghost
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleDownloadPDF({
                        pages: [<InstallationCertificate {...reportData} />],
                        fileName: `${reportData?.customer?.name} - Installation Certificate`,
                      });
                    }}
                    ghost
                  >
                    Download
                  </Button>

                  {userData?.employee?.accessType ===
                    `${"Super Admin" || "Admin"}` && (
                    <>
                      <Button type="primary" onClick={handleDelete}>
                        Delete
                      </Button>
                      {!edit && (
                        <Link
                          to={`/edit/installationCertificate/${
                            view ? params.id : reportId
                          }`}
                        >
                          <Button type="primary">Edit</Button>
                        </Link>
                      )}
                    </>
                  )}
                </Space>
                <PDFViewer width={"100%"} height={1200}>
                  <PDFDocument
                    pages={[<InstallationCertificate {...reportData} />]}
                  />
                </PDFViewer>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InstallationCertificateForm;
