import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Dropdown,
  Table,
  Modal,
  Radio,
  Upload,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Select } from "antd";
import { LuPlus } from "react-icons/lu";
import moment from "moment";
import axios from "axios";
const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ManageLead = ({ isEdit }) => {
  const [leadData, setLeadData] = useState();
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = useState(undefined);
  const [employeeValue, setEmployeeValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [attachmentFileId, setAttachmentFileId] = useState();
  const [systems, setSystems] = useState([]);
  const [employee, setEmployee] = useState(undefined);
  const [initialEmployee, setInitialEmployee] = useState();

  const getLeadDetails = async () => {
    try {
      const response = await APICall(`/lead/${params.id}`, {}, 1);
      setLeadData(response.data);
      form.setFieldsValue({
        employeeId: {
          label: response.data.employee.user.name,
          value: response.data.employee._id,
        },
        leadName: response.data.leadName,
        source: response.data.source,
        type: response.data.type,
        lastEngagementDate: dayjs(new Date(response.data.lastEngagementDate)),
        leadQuality: response.data.leadQuality,
        interestScore: response.data.interestScore,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Failed getting data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      getLeadDetails();
    }
  }, []);

  const onFinish = async (values) => {
    setIsLoading(!isLoading);
    if (isEdit) {
      const data = { ...values };
      data.employeeId =
        typeof values.employeeId === "string"
          ? values.employeeId
          : values.employeeId.value;
      const response = await APICall(`/lead/${params.id}`, data, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("Lead has been updated successfully.");
        navigate("/lead");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const data = { ...values };
        const response = await APICall(`/lead`, data, 2);
        setIsLoading(false);
        if (response.data) {
          message.success("Lead has been created successfully.");
          navigate(`/lead`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.errors.body[0].message);
      }
    }
  };

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const fetchEmployeeList = async (searchValue) => {
    const res = await APICall(
      `/employee?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((employee) => ({
      label: employee.user.name,
      value: employee._id,
    }));
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Card title={params.id ? "Edit Lead" : "Create Lead"}>
            <Form
              form={form}
              layout="vertical"
              // wrapperCol={{
              //     span: 20,
              // }}
              onFinish={onFinish}
              initialValues={{ includeGST: true }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Row gutter={10}>
                    <Col span={8}>
                      <Form.Item
                        name="leadName"
                        label="Lead Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter lead name",
                          },
                        ]}
                      >
                        <Input placeholder="E.g. Rachana Kapse" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Source"
                        name="source"
                        rules={[
                          {
                            required: true,
                            message: "Please enter source",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. Just Dial" />
                      </Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="type"
                        label="Select Type"
                        rules={[
                          {
                            required: true,
                            message: "Please select the type",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="e.g. Industrial"
                          // onChange={handleChange}
                          options={[
                            {
                              label: "Industrial",
                              value: "Industrial",
                            },
                            {
                              label: "Residential",
                              value: "Residential",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Last Engagement Date"
                        name="lastEngagementDate"
                      >
                        <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Row gutter={10}>
                        <Col span={12}>
                          <Item label="Lead Quality" name="leadQuality">
                            <InputNumber
                              placeholder="e.g. 4"
                              style={{ width: "100%" }}
                              controls={false}
                            />
                          </Item>
                        </Col>

                        <Col span={12}>
                          <Item label="Interest Score" name="interestScore">
                            <InputNumber
                              placeholder="e.g. 8"
                              style={{ width: "100%" }}
                              controls={false}
                            />
                          </Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="employeeId" label="Employee">
                        <DebounceSelect
                          value={employee}
                          placeholder="Select Employee"
                          fetchOptions={fetchEmployeeList}
                          defaultValue={initialEmployee}
                          onChange={(newValue) => {
                            setEmployee(newValue);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Item>
                        <Button type="primary" htmlType="submit" tabIndex={8}>
                          {isEdit ? "Save" : "Create"}
                        </Button>
                      </Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageLead;
