import React from 'react';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';

const SurveyCard = ({ survey, isCompleted, exportSurvey }) => {
  const formatSurveyNumber = (number) => {
    return number.toString().padStart(4, '0');
  };

  const formatSurveyDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  const Actions = () => {
    return <div>
      { !isCompleted ?
        <Link to={`/survey/${survey._id}/buildings?customerId=${survey.customerId}&customerName=${survey.customer.user.name}&pagename=Surveys`}>
          <Button type="primary" size='small' ghost>Conduct</Button>
        </Link> : <Space>
            <Link
              to={`/survey/${survey._id}/buildings?customerId=${survey.customerId}&customerName=${survey.customer.user.name}&pagename=Surveys`}
            >
              <Button type="primary" size="small" ghost>
                Edit
              </Button>
            </Link>
            <Button
              type="primary"
              size="small"
              ghost
              onClick={() => exportSurvey(survey._id)}
            >
              Export
            </Button>
          </Space>
      }
    </div>
  };

  return (
    <div style={{ margin: '1rem' }}>
      <Card
        title={`SN${formatSurveyNumber(survey.surveyNo)}`}
        extra={<Actions/>}
        style={{
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        }}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: '0.5rem' }}>
          <Col xs={24} sm={8}>
            <div style={{ fontWeight: 'bold', color: '#595959' }}>Customer</div>
            <div style={{ fontSize: '1rem' }}>{survey.customer.user.name}</div>
          </Col>
          <Col xs={24} sm={8}>
            <div style={{ fontWeight: 'bold', color: '#595959' }}>Type</div>
            <Tag color={survey.type.charAt(0) === 'i' ? "green" : "yellow"} style={{ fontSize: '0.9rem', padding: '0.2rem 0.6rem' }}>
              {survey.type.charAt(0).toUpperCase() + survey.type.slice(1)}
            </Tag>
          </Col>
          <Col xs={24} sm={8}>
            <div style={{ fontWeight: 'bold', color: '#595959' }}>Survey Date</div>
            <div style={{ fontSize: '1rem' }}>{formatSurveyDate(survey.surveyDate)}</div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SurveyCard;
