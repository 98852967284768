import moment from "moment";
import React from "react";

const styleContainer = {
  margin: "20px 0",
};

const styleDay = {
  fontSize: "5rem", 
  fontWeight: "bold", 
  lineHeight: "1", 
  color: "#333", 
};

const styleMonth = {
  fontSize: "2rem", 
  fontWeight: "lighter", 
  color: "#666", 
};

const BeautifiedDate = ({ date }) => {
  return (
    <div style={styleContainer}>
      <div style={styleDay}>
        {moment(date).format("DD")} 
      </div>
      <div style={styleMonth}>
        {moment(date).format("MMMM")} 
      </div>
    </div>
  );
};

export default BeautifiedDate;
