import React, { useEffect, useState, useCallback } from "react";
import { LuPlus } from "react-icons/lu";
import { Row, Card, Col, Table, Space, Button, message, Modal, Input } from "antd";
import { Link, useLocation } from "react-router-dom";
import APICall from "../../services/apiservices";
import qs from "qs";

const { Search } = Input;

const fetchList = async (tableParams, setCashLedgers, setIsLoading, type, setTableParams) => {
  const page = tableParams.pagination.current;
  const limit = tableParams.pagination.pageSize;
  const searchTerm = tableParams.filters.searchTerm || "";
  const response = await getLeads(searchTerm, page, limit, type);
  setCashLedgers(response.documents);
  setIsLoading(false);

  setTableParams((prevParams) => ({
    ...prevParams,
    pagination: {
      ...prevParams.pagination,
      total: response.total,
    },
  }));
};

const getLeads = async (searchTerm, page, limit, type) => {
  try {
    if (searchTerm === undefined) searchTerm = "";
    const queryParams = {
      page,
      type,
      limit,
      query: searchTerm,
    };
    const queryString = qs.stringify(queryParams);
    const response = await APICall(`/cashLedger?${queryString}`, {}, 1);
    return response.data;
  } catch (error) {
    message.error("failed to get Lead data");
  }
};

const columns = (tableParams) => [
  {
    title: "Sr. No.",
    key: "srNo",
    render: (_, record, index) => {
      return (
        tableParams.pagination.pageSize *
        (tableParams.pagination.current - 1) +
        index +
        1
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Client Name",
    dataIndex: "clientName",
    key: "clientName",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Service",
    dataIndex: 'service',
    key: "service",
  },
  {
    title: "Mode of Payment",
    dataIndex: "modeOfPayment",
    key: "modeOfPayment",
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Link to={`/cashLedger/details/${record._id}`}>
        <Button type="primary" ghost>
          View
        </Button>
      </Link>
    ),
    align: "center",
    fixed: "right",
  },
];

const CashLedgers = () => {
  const [cashLedgers, setCashLedgers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  let { type } = queryParams;
  if (!type) type = "Sell";

  useEffect(() => {
    fetchList(tableParams, setCashLedgers, setIsLoading, type, setTableParams);
  }, []);

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCashLedgers([]);
    }
  }, [tableParams]);

  const onSearch = useCallback((value) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      filters: {
        ...prevParams.filters,
        searchTerm: value,
      },
    }));
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Card
          title={`${type} Cash Ledgers`}
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search"
                  allowClear
                  enterButton
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to={`/cashLedger/manage?type=${type}`}>
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    {`Create ${type} Cash Ledger`}
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            dataSource={cashLedgers}
            columns={columns(tableParams)}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
            size="small"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CashLedgers;
