import React, { useState, useEffect } from "react";
import { LuPlus } from "react-icons/lu";
import { LiaExternalLinkAltSolid } from "react-icons/lia";

import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  message,
  Modal,
  Dropdown,
  Space,
  Input,
  Image,
  Tooltip
} from "antd";
import APICall from "../../services/apiservices";

const { confirm } = Modal;
const { Search } = Input;

const Items = () => {
  const [items, setItems] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getItems = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/item?page=${page}&limit=${limit}&name=${searchTerm}`,
        {},
        1
      );
      //console.log(response);
      //setItems(response.data.documents);
      //console.log(response.data.documents);
      return response.data;
      // setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch item details");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getItems(1, 10);
  // }, [reload]);

  const fetchList = async () => {
    // setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getItems(searchTerm, page, limit);
    //console.log(response)
    // console.log(response)
    setItems(response.documents);
    setIsLoading(false);

    setTableParams(prev => ({
      ...prev,
      pagination: {
          ...prev.pagination,
          total: response.total, // Ensure total pages are updated based on response
          current: page, // Ensure current page is correctly set
      },
  }));
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, record) => {
        return <Image src={record.photoURL} width={60} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record.name;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => {
        const desc = record.description || '';
        const shortDesc = desc.length > 50 ? `${desc.substring(0, 50)}...` : desc;
        return (
          <Tooltip title={desc}>
            <span>{shortDesc}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      render: (_, record) => {
        return (
          <a
            target="blank"
            href={`https://vakilsearch.com/hsn-code/search/${record.hsn}`}
          >
            {record.hsn}
            {<LiaExternalLinkAltSolid style={{ marginLeft: "3px" }} />}
          </a>
        );
      },
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      render: (_, record) => {
        return record.sgst ? record.sgst + "%" : "NA";
      },
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      render: (_, record) => {
        return record.cgst ? record.cgst + "%" : "NA";
      },
    },
    {
      title: "Price (₹)",
      dataIndex: "price",
      render: (_, record) => {
        return <>₹{record.price}</>;
      },
    },
    // {
    //   title: "Total Price (₹)",
    //   dataIndex: "price",
    //   render: (_, record) => {
    //     return <>₹ {record.price}</>;
    //   },
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/items/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/items/manage/${record._id}`}>Edit</Link>,
        //       },

        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDelete(record);
        //         },
        //         danger: "true",
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Link to={`/items/${record._id}`}>
            <Button type="primary" ghost>
              View
            </Button>
          </Link>
        </Space>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setItems([]);
    }
  };

  const onSearch = (value) => {
    setTableParams(prev => ({
        filters: {
            searchTerm: value,
        },
        pagination: {
            ...prev.pagination,
            current: 1,
        },
    }));
  };


  const exportItems = async () => {
    try {
      const response = await APICall("/item/export", {}, 1);
      const file = new Blob([response.data], { type: "text/plain" });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = "items.csv";
      document.body.appendChild(element);
      element.click();
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Items"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search an item"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/items/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create Item
                  </Button>
                </Link>
                <Button
                  type="primary"
                  icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  onClick={exportItems}
                >
                  Export Items
                </Button>
              </Space>
            </Row>
          }
        >
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={items}
            onChange={handleTableChange}
            size="small"
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Items;
