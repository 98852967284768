import React, { useEffect, useState } from "react";
import moment from "moment";

import BeautifiedDate from "./BeautifiedDate";
import SingleTask from "./SingleTask";
import ScrollableDateHeader from "./ScrollabeDateHeader";

const TaskDiary = ({ tasks, setTasks, selectedDate, setSelectedDate, changeStatus, handleDelete}) => {
  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <ScrollableDateHeader onDateSelect={handleDateSelect} />
      <BeautifiedDate date={selectedDate} />
      <div>
        {tasks?.length > 0 ? (
          tasks.map((task) => (
            <SingleTask
              key={task._id}
              task={task}
              onStatusChange={changeStatus}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          <p>No tasks for this date.</p>
        )}
      </div>
    </div>
  );
};

export default TaskDiary;
