import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  message,
  Modal,
  Dropdown,
  Space,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import APICall from "../../services/apiservices";
const { confirm } = Modal;
const { Search } = Input;

const Quotations = () => {
  const [Quotations, setQuotations] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getQuotations = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/quotation?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      return response.data;
      // setQuotations(response.data.documents);
      // setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch Quotation details");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getQuotations(1, 10);
  // }, [reload]);

  const fetchList = async () => {
    // setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getQuotations(searchTerm, page, limit);
    //console.log(response)
    // console.log(response)
    setQuotations(response.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    // {
    //   title: "Customer Code",
    //   key: "customerCode",
    //   render: (_, data) => {
    //     return data.customer.customerCode;
    //   },
    // },
    {
      title: "Customer Name",
      key: "name",
      render: (_, data) => {
        return data.user.name;
      },
    },
    {
      title: "Date",
      key: "date",
      render: (_, data) => {
        return moment(data.date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Amount (₹)",
      key: "amount",
      render: (_, data) => {
        return data.amount;
      },
    },
    {
      title: "Discount (₹)",
      key: "discount",
      render: (_, data) => {
        return `${data.discountAmount}`;
      },
    },
    {
      title: "CGST (₹)",
      key: "cgst",
      render: (_, data) => {
        return `${data.cgstAmount}`;
      },
    },
    {
      title: "SGST (₹)",
      key: "cgst",
      render: (_, data) => {
        return `${data.sgstAmount}`;
      },
    },
    {
      title: "Total Amount (₹)",
      key: "totalAmount",
      render: (_, data) => {
        return data.totalAmount;
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_, data) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       // {
        //       //   key: 1,
        //       //   label: <Link to={`/quotation/:${data._id}`}>Open</Link>,
        //       // },
        //       // {
        //       //   key: 2,
        //       //   label: <Link to={`/quotation/manage/:${data._id}`}>Edit</Link>,
        //       // },
        //       {
        //         key: 1,
        //         label: <Link to={`/quotations/${data._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: (
        //           <span
        //             style={{ color: "red" }}
        //             onClick={() => handleDelete(data)}
        //           >
        //             Delete
        //           </span>
        //         ),
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Link to={`/quotations/${data._id}`}>
            <Button type="primary" ghost>
              View
            </Button>
          </Link>
          {/* <Link to={`/quotations/manage/${data._id}`}>
            <Button type="primary" ghost size="small">
              Edit
            </Button>
          </Link>
          <Button
            type="primary"
            onClick={() => handleDelete(data)}
            size="small"
          >
            Delete
          </Button> */}
        </Space>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setQuotations([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Quotations"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search by customer"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/quotations/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create Quotation
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            // style={{ maxWidth: 1200 }}
            loading={isLoading}
            columns={columns}
            dataSource={Quotations}
            onChange={handleTableChange}
            size="small"
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Quotations;
