import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Form, Input, Row, Spin, message, Space, Button, Modal } from "antd";
import APICall from "../../../services/apiservices";
import BOQDetailsField from "./BOQDetailsField";
import TextArea from "antd/es/input/TextArea";
import { Link, useNavigate } from "react-router-dom";
import downloadXLSXWorking from "./exportworking";
import downloadXLSX from "./export";

const BOQ = () => {
  const params = useParams();
  const [boqData, setBoqData] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [customer, setCustomer] = useState(undefined);
  useEffect(() => {
    const fetchBoqData = async () => {
      try {
        const response = await APICall(`/boq/${params.boqId}`, {}, 1);
        setBoqData(response.data);
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch BOQ data");
        setLoading(false);
      }
    };

    fetchBoqData();
  }, [params.boqId]);

  useEffect(() => {
    const fetchCustomerName = async (customerId) => {
      try {
        const response = await APICall(`/customer/${customerId}`, {}, 1);
        setCustomerName(response.data.user.name || "Unknown Customer");
        setCustomer(JSON.stringify(response.data));
      } catch (error) {
        message.error("Failed to fetch customer details");
      }
    };

    if (boqData?.customerId) {
      fetchCustomerName(boqData.customerId);
    }
  }, [boqData]);

  useEffect(() => {
    if (customerName) {
      form.setFieldsValue({ customer: customerName });
    }
    if (boqData?.termsAndConditions) {
      form.setFieldsValue({ termsandconditions: boqData.termsAndConditions });
    }
  }, [customerName, boqData, form]);

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete BOQ ${customerName}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const res = await APICall(`/boq/${boqData._id}`, {}, 4);
        // setReload(true);
        message.success("BOQ deleted successfully!");
        navigate("/boq");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const getorganizationdetails = async () => {
    try {
      const response = await APICall(`/organization/649bff0f87a35d7bececa3d8`, {}, 1);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotals = (items) => {
    let totalMaterialAmount = 0;
    let totalLabourAmount = 0;
    let totalInternalLabourAmount = 0;
    let totalInternalMaterialAmount = 0;

    items.forEach((item) => {
      if (item) {
        totalMaterialAmount += item.materialTotal || 0;
        totalLabourAmount += item.labourTotal || 0;
        totalInternalMaterialAmount += item.internalmaterialTotal || 0;
        totalInternalLabourAmount += item.internallabourTotal || 0;
  
        if (item.variations && item.variations.length > 0) {
          const subItemTotals = calculateTotals(item.variations);
          totalMaterialAmount += subItemTotals.materialTotal;
          totalLabourAmount += subItemTotals.labourTotal;
          totalInternalMaterialAmount += subItemTotals.internalmaterialTotal;
          totalInternalLabourAmount += subItemTotals.internallabourTotal;
        }
      }
    });
  
    return { materialTotal: totalMaterialAmount, labourTotal: totalLabourAmount,
      internalmaterialTotal: totalInternalMaterialAmount, internallabourTotal: totalInternalLabourAmount  
     };
  };

  const handleGenerateWorking = async () => {
    try {
      const filteredSystems = boqData.mega
        .map((record) => {
          const filteredItems = record.items;
          const { materialTotal, labourTotal, internalmaterialTotal, internallabourTotal } = calculateTotals(filteredItems);

          return {
            ...record,
            items: filteredItems,
            total: {
              materialTotal,
              labourTotal, 
              grandAmount: materialTotal + labourTotal,
              internalmaterialTotal: internalmaterialTotal,
              internallabourTotal: internallabourTotal, 
              internalgrandAmount: internalmaterialTotal + internallabourTotal,
            },
          };
        })
        .filter((record) => record.items.length > 0);

        let totalLabourAmount = 0;
        let totalMaterialAmount = 0;
        let totalInternalLabourAmount = 0;
        let totalInternalMaterialAmount = 0;

        filteredSystems.forEach((system) => {
          totalLabourAmount += Number(system.total.labourTotal) || 0;
          totalMaterialAmount += Number(system.total.materialTotal) || 0;
          totalInternalLabourAmount += Number(system.total.internallabourTotal) || 0;
          totalInternalMaterialAmount += Number(system.total.internalmaterialTotal) || 0;
        });
        const customerData = JSON.parse(customer);
        const organizationData = await getorganizationdetails();
        if (!organizationData) {
          message.error("organization details not found");
          return;
        }
        const data = {
          boqNumber: boqData.boqNumber,
          organization: {
            name: organizationData?.name,
            address: organizationData?.address,
            mobile: organizationData?.mobile,
            email: organizationData?.email,
          },
          customer: {
            name: customerData?.user?.name,
            address: customerData?.user?.address,
          },
          systems: filteredSystems,
          total: {
            materialAmount: totalMaterialAmount,
            labourAmount: totalLabourAmount,
            grand: totalMaterialAmount + totalLabourAmount,
            internalmaterialAmount: totalInternalMaterialAmount,
            internallabourAmount: totalInternalLabourAmount,
            internalgrand: totalInternalMaterialAmount + totalInternalLabourAmount,
          },
          termsAndConditions: boqData.termsAndConditions,
        };
        message.success("Working document generated successfully!");
        downloadXLSXWorking(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateBOQ = async () => {
    try {
      const filteredSystems = boqData.mega
        .map((record) => {
          const filteredItems = record.items;
          const { materialTotal, labourTotal } = calculateTotals(filteredItems);

          return {
            ...record,
            items: filteredItems,
            total: {
              materialTotal,
              labourTotal, 
              grandAmount: materialTotal + labourTotal,
            },
          };
        })
        .filter((record) => record.items.length > 0);

        let totalLabourAmount = 0;
        let totalMaterialAmount = 0;

        filteredSystems.forEach((system) => {
          totalLabourAmount += Number(system.total.labourTotal) || 0;
          totalMaterialAmount += Number(system.total.materialTotal) || 0;
        });
        const customerData = JSON.parse(customer);
        const organizationData = await getorganizationdetails();
        if (!organizationData) {
          message.error("organization details not found");
          return;
        }
        const data = {
          boqNumber: boqData.boqNumber,
          organization: {
            name: organizationData?.name,
            address: organizationData?.address,
            mobile: organizationData?.mobile,
            email: organizationData?.email,
          },
          customer: {
            name: customerData?.user?.name,
            address: customerData?.user?.address,
          },
          systems: filteredSystems,
          total: {
            materialAmount: totalMaterialAmount,
            labourAmount: totalLabourAmount,
            grand: totalMaterialAmount + totalLabourAmount,
          },
          termsAndConditions: boqData.termsAndConditions,
        };
        message.success("BOQ generated successfully!");
        downloadXLSX(data);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <>
      <Card
          title="BOQ Details"
          extra={
            <Space>
              <Link>
                <Button type="primary"  onClick={handleGenerateWorking}>
                  Generate Working</Button>
              </Link>
              <Link>
                <Button type="primary"  onClick={handleGenerateBOQ}>
                  Generate BOQ</Button>
              </Link>
              <Link to={`/boq/manage/${boqData?._id}`}>
                <Button type="primary">Edit</Button>
              </Link>
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
        <Row gutter={16}>
          <Col span={24}>
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Customer" name="customer">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>

      <Card style={{ marginBottom: 16 }}>
        {boqData?.mega?.map((item, index) => (
          <div key={index}>
            <h3>{item.name}</h3>
            {item.items?.map((subItem, subIndex) => (
              <BOQDetailsField key={subIndex} item={subItem} />
            ))}
          </div>
        ))}
      </Card>

      <Card style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Terms & Conditions"
                    name="termsandconditions"
                  >
                    <TextArea
                      rows={10}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default BOQ;
