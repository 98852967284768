import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  Tag,
  message,
  Descriptions,
  Space,
  Modal,
  Form,
  Input,
  DatePicker,
} from "antd";
import APICall from "../../../services/apiservices";
import moment from "moment";
import downloadXLSX from "../../../employee/features/survey/export";
import downloadFireExtinguisherXLSX from "../../../employee/features/survey/fireExtExport";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
const { confirm } = Modal;
const { Item } = Form;
const AMCDetails = () => {
  const [visitsForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [amcDetails, setAMCDetails] = useState([]);
  const { amcId } = useParams();
  const [visitId, setVisitId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(undefined);
  const [employeeValue, setEmployeeValue] = useState(undefined);
  const navigate = useNavigate();

  const getAMCDetails = async () => {
    try {
      const response = await APICall(`/amc/${amcId}`, {}, 1);
      setAMCDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch amc details");
      setIsLoading(false);
    }
  };

  const handleEditVisit = async (formData) => {
    try {
      delete formData.assignedTo;
      const dataTosend = {
        assignedTo: formData.data.data,
        scheduledDate: formData.scheduledDate,
        description: formData.description,
      };
      const res = await APICall(`/visits/${visitId}`, dataTosend, 3);
      setIsModalOpen(false);
      setIsLoading(false);
      message.success("Visit updated successfully!");
      getAMCDetails();
    } catch (error) {
      message.error(error.response.data.message);
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAMCDetails();
  }, []);

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  const exportVisit = async (visitId) => {
    try {
      const res = await APICall(`/visits/${visitId}/conducted`, {}, 1);

      if (!res?.data?.visit?.isFireExtinguisher) {
        const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.visit?.customerId?.userId?.name,
          },
          employee: {
            name: res?.data?.visit?.assignedTo?.userId?.name,
          },
          date: moment(res?.data?.visit?.visitDate).format("DD/MM/YYYY"),
          buildings: res.data.visitBuildings.map((sb) => {
            const areas = sb?.newFloors.map((nf, index) => ({
              area: nf?.floor.name,
              srNo: index + 1,
              equipments: nf?.equipments ?? {
                adaptor: "NA",
                hoseBox: "NA",
                branchPipe: "NA",
                hosePipe: "NA",
                hydrantValve: "NA",
                hoseReel: "NA",
                fireExtinguishers: "NA",
                hoseBoxGlass: "NA",
                keyGlass: "NA",
                hydrantValveWheel: "NA",
                hoseReelBallValve: "NA",
              },
            }));
            const requirements = JSON.parse(JSON.stringify(requirementsArray));
            areas.map((area) => {
              Object.keys(area.equipments).map((k) => {
                if (area.equipments[k] == "OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].good = requirements[k].good + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].good = finalRequirements[k].good + 1;
                } else if (area.equipments[k] == "Not OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].requirement = requirements[k].requirement + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].requirement =
                    finalRequirements[k].requirement + 1;
                }
              });
            });
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
              requirements,
            };
          }),
        };
        exportData.requirements = finalRequirements;
        downloadXLSX(exportData, "Visit");
      } else {
        console.log(res);
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.visit?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.visit?.employee?.user?.name,
          },
          date: moment(res?.data?.visit?.visitDate).format("DD/MM/YYYY"),

          buildings: res.data.visitBuildings.map((sb) => {
            const areas = sb?.newFloors.map((floor, index) => ({
              srNo: index + 1,
              area: floor?.floor.name,
              fireExtinguisherInfo: {
                type: floor?.fireExtinguisherInfo?.type ?? "NA",
                capacity: floor?.fireExtinguisherInfo?.capacity ?? "NA",
                pressure: floor?.fireExtinguisherInfo?.pressure ?? "NA",
                weight: floor?.fireExtinguisherInfo?.weight ?? "NA",
                serialNo: floor?.fireExtinguisherInfo?.serialNo ?? "NA",
                installationRefillingDate: floor?.fireExtinguisherInfo
                  ?.installationRefillingDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.installationRefillingDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                refillingDueDate: floor?.fireExtinguisherInfo?.refillingDueDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.refillingDueDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                freeAccess: floor?.fireExtinguisherInfo?.freeAccess ?? "NA",
                identificationTag:
                  floor?.fireExtinguisherInfo?.identificationTag ?? "NA",
                forABCPressureGuage:
                  floor?.fireExtinguisherInfo?.forABCPressureGuage ?? "NA",
                hoseTightFitting:
                  floor?.fireExtinguisherInfo?.hoseTightFitting ?? "NA",
                isSafetyPinGood:
                  floor?.fireExtinguisherInfo?.isSafetyPinGood ?? "NA",
                forCO2HoseCondition:
                  floor?.fireExtinguisherInfo?.forCO2HoseCondition ?? "NA",
                forCO2HornHandle:
                  floor?.fireExtinguisherInfo?.forCO2HornHandle ?? "NA",
                isFEOK: floor?.fireExtinguisherInfo?.isFEOK ?? "NA",
                remark: floor?.fireExtinguisherInfo?.remark ?? "NA",
              },
            }));
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
            };
          }),
        };

        downloadFireExtinguisherXLSX(exportData, "Visit");
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/amc/${amcId}`, {}, 4);
        message.success("AMC deleted successfully!");
        navigate("/amcs");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const fetchEmployeeList = async (searchValue) => {
    const res = await APICall(
      `/employee?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((employee) => ({
      label: `${employee.user.name}`,
      value: employee._id,
      data: employee,
    }));
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "serialNumber",
      render: (_, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => {
        return moment(record?.scheduledDate).format("DD/MM/YY");
      },
      // align: "center",
    },
    {
      title: "Description",
      key: "description",
      render: (record) => {
        return record?.description;
      },
      // align: "center",
    },

    {
      title: "Status",
      key: "status",
      render: (record) => {
        return (
          <Tag color={record.isConducted ? "blue" : "red"}>
            {record.isConducted ? "Conducted" : "Pending"}
          </Tag>
        );
      },
    },
    {
      title: "Assigned To",
      key: "assignedTo",
      render: (record) => {
        return record?.assignedTo?.userId?.name || "Not Assigned";
      },
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.isConducted ? (
          <Button
            type="primary"
            onClick={() => {
              exportVisit(record._id);
            }}
            ghost
            size="small"
          >
            Export
          </Button>
        ) : (
          <Button
            type="primary"
            ghost
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setVisitId(record._id);
              visitsForm.setFieldsValue({
                assignedTo: {
                  value: record?.assignedTo?._id,
                  label: record?.assignedTo?.user?.name,
                },
                scheduledDate: dayjs(record?.scheduledDate),
                description: record?.description,
              });
            }}
            size="small"
          >
            Edit
          </Button>
        ),
      align: "center",
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="AMC Details"
          loading={isLoading}
          extra={
            <Space>
              <Link to="/amcs">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Link to={`/amcs/${amcId}`}>
                <Button type="primary">Export</Button>
              </Link>
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions bordered layout="vertical" style={{ width: "100%" }}>
            <Descriptions.Item label="AMC Number">
              {"AMC" + amcDetails?.amcNo?.toString().padStart(4, "0")}
            </Descriptions.Item>
            <Descriptions.Item label="Customer Name">
              {amcDetails?.customer?.user?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {amcDetails?.customer?.user?.address}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              <Tag color="blue">{amcDetails?.type}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Duration(Month)">
              {amcDetails?.duration + " Months"}
            </Descriptions.Item>
            <Descriptions.Item label="Toatal Visits">
              {amcDetails?.visitsCount}
            </Descriptions.Item>
            <Descriptions.Item label="Contract Date">
              {dayjs(amcDetails?.contractDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date">
              {dayjs(amcDetails?.expiryDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Systems">
              {amcDetails?.systemTypes?.map((system) => {
                return system.systemTypeId.name + ", ";
              })}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={24} style={{ marginTop: "10px" }}>
        <Card title="Visits">
          <Table
            columns={columns}
            dataSource={amcDetails?.visits}
            size="small"
            loading={isLoading}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
      <Modal
        title="Add Visit"
        open={isModalOpen}
        onOk={() => {
          visitsForm.submit();
        }}
        onCancel={() => setIsModalOpen(false)}
        centered
        okText="Save"
        loading={isLoading}
      >
        <Form form={visitsForm} layout="vertical" onFinish={handleEditVisit}>
          <Form.Item name="description" label="Enter Description">
            <Input placeholder="e.g. First visit" tabIndex={10} />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Item label="Data" name="data" hidden></Item>

              <Form.Item
                name="assignedTo"
                label="Select Assigned Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select the employee",
                  },
                ]}
              >
                <DebounceSelect
                  value={value}
                  placeholder="e.g. John Doe"
                  fetchOptions={fetchEmployeeList}
                  onChange={(newValue, data) => {
                    setEmployeeValue(newValue);
                    visitsForm.setFieldsValue({ data: data });
                  }}
                  style={{ width: "100%" }}
                  tabIndex={11}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Item
                label="Scheduled Date"
                name="scheduledDate"
                rules={[
                  {
                    required: true,
                    message: "Please select scheduled date",
                  },
                ]}
              >
                <DatePicker
                  format={'DD-MM-YYYY'}
                  // disabledDate={disabledDate}
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
};

export default AMCDetails;
