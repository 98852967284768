import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Card,
  Button,
  Checkbox,
  Space,
  DatePicker,
  message,
  Modal,
} from "antd";
import APICall from "../../../services/apiservices";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";

import { PDFViewer, Document } from "@react-pdf/renderer";
import { PDFDocument, handleDownloadPDF } from "../../../pdfs/pdfService";
import FormBPDF from "../../../pdfs/formB";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";

const { confirm } = Modal;
const FormB = ({ view, edit }) => {
  const [value, setValue] = useState(undefined);
  const [reportForm] = Form.useForm();
  const todayDate = dayjs();
  const [reportData, setReportData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reportId, setReportId] = useState();
  const params = useParams();
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete report?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/report/${reportId}`, {}, 4);
        // getQuotations(0, 10);
        message.success("Report deleted successfully!");
        navigate("/reports/formB");
      },
    });
  };

  const generateReport = async (formData) => {
    setIsLoading(true);
    try {
      let date;
      let endDate;
      if (formData?.range === 0) {
        date = new Date(`${formData?.year["$y"]}-01-01`);
        endDate = new Date(`${formData?.year["$y"]}-06-30`);
      } else {
        date = new Date(`${formData?.year["$y"]}-07-01`);
        endDate = new Date(`${formData?.year["$y"]}-12-31`);
      }
      const dataTosend = {
        organizationId: "649bff0f87a35d7bececa3d8",
        date: date,
        endDate: endDate,
        ...formData,
        type: "Form B",
        extraInformation: { issuedDate: formData?.issuedDate },
      };

      let res;
      if (!view) {
        res = await APICall("/report", dataTosend, 2);
        setReportId(res.data.data._id);
      }
      const res2 = await APICall(
        `/report/${view ? params.id : res.data.data._id}`,
        {},
        1
      );
      const res3 = await APICall(`/organization/${res2?.data?.organizationId}`, {}, 1);

      let letterhead = "";
      let letterheadFooter = "";
      if (view) {
        letterhead = res2?.data?.letterhead?.header;
        letterheadFooter = res2?.data?.letterhead?.footer;
      } else if (formData?.includeLetterheadHeader) {
        letterhead = res2?.data?.letterhead?.header;
        letterheadFooter = res2?.data?.letterhead?.footer;
      }
      setReportData({
        letterheader: letterhead,
        letterfooter: letterheadFooter,
        referenceNumber: res2?.data?.reportCode,
        headingDate: moment(res2?.data?.extraInformation.issuedDate).format(
          "DD/MM/YYYY"
        ),
        customer: {
          name: res2?.data?.customer?.user?.name,
          address: res2?.data?.customer?.user?.address,
        },
        startDate: moment(res2?.data?.date).format("DD/MM/YYYY"),
        endDate: moment(res2?.data?.endDate).format("DD/MM/YYYY"),
        organization: {
          name: res3?.data?.name,
          address: res3?.data?.address,
          licenseNumbers: [res3?.data?.licenceNumber],
        },
        place: res2?.data?.place,
        footerDate: moment(res2?.data?.extraInformation.issuedDate).format(
          "DD/MM/YYYY"
        ),
      });
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getReportData = async () => {
    setIsLoading(true);
    try {
      const res = await APICall(`/report/${params.id}`, {}, 1);
      console.log(res.data);
      reportForm.setFieldsValue({
        customerId: {
          label: res?.data?.customer?.user?.name,
          value: res?.data?.customerId,
        },
        date: dayjs(res?.data?.date),
        endDate: dayjs(res?.data?.endDate),
        issuedDate: dayjs(res?.data?.extraInformation?.issuedDate),
        year: dayjs(res?.data?.date),
        range: dayjs(res?.data?.date)["$M"] === 0 ? 0 : 1,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to fetch data");
    }
  };

  const updateReport = async (formData) => {
    setIsLoading(true);
    try {
      let date;
      let endDate;
      if (formData?.range === 0) {
        date = new Date(`${formData?.year["$y"]}-01-01`);
        endDate = new Date(`${formData?.year["$y"]}-06-30`);
      } else {
        date = new Date(`${formData?.year["$y"]}-07-01`);
        endDate = new Date(`${formData?.year["$y"]}-12-31`);
      }
      const dataTosend = {
        ...formData,
        customerId: formData.customerId.value ?? formData.customerId,
        date: date,
        endDate: endDate,
        extraInformation: { issuedDate: formData?.issuedDate },
      };
      delete formData.customerId;
      const res = await APICall(`/report/${params.id}`, dataTosend, 3);
      message.success("Report updated successfully!");
      const res2 = await APICall(`/report/${params.id}`, {}, 1);
      const res3 = await APICall(
        `/organization/${res?.data?.data?.organizationId}`,
        {},
        1
      );

      let letterhead = "";
      let letterheadFooter = "";
      if (formData?.includeLetterheadHeader) {
        letterhead = res2?.data?.letterhead?.header;
        letterheadFooter = res2?.data?.letterhead?.footer;
      } else if (view) {
        letterhead = res2?.data?.letterhead?.header;
        letterheadFooter = res2?.data?.letterhead?.footer;
      }
      setReportData({
        letterheader: letterhead,
        letterfooter: letterheadFooter,
        referenceNumber: res2?.data?.reportCode,
        headingDate: moment(res2?.data?.extraInformation.issuedDate).format(
          "DD/MM/YYYY"
        ),
        customer: {
          name: res2?.data?.customer?.user?.name,
          address: res2?.data?.customer?.user?.address,
        },
        startDate: moment(res2?.data?.date).format("DD/MM/YYYY"),
        endDate: moment(res2?.data?.endDate).format("DD/MM/YYYY"),
        organization: {
          name: res3?.data?.name,
          address: res3?.data?.address,
          licenseNumbers: [res3?.data?.licenceNumber],
        },
        place: res2?.data?.place,
        footerDate: moment(res2?.data?.extraInformation.issuedDate).format(
          "DD/MM/YYYY"
        ),
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to update report");
    }
  };

  useEffect(() => {
    if (view) {
      generateReport();
      setReportId(params.id);
    }
    if (edit) {
      getReportData();
    }
  }, [edit, view]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Card title="Form B Report" loading={view ? isLoading : false}>
          {!view && (
            <Form
              form={reportForm}
              layout="vertical"
              onFinish={edit ? updateReport : generateReport}
              initialValues={{ place: "Pune" }}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="customerId"
                    label="Select Customer"
                    rules={[
                      {
                        required: true,
                        message: "Please select the customer",
                      },
                    ]}
                  >
                    <DebounceSelect
                      value={value}
                      placeholder="Select a user"
                      fetchOptions={fetchCustomerList}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="year"
                    label="Select Year"
                    rules={[
                      {
                        required: true,
                        message: "Please select the customer",
                      },
                    ]}
                  >
                    <DatePicker.YearPicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="range"
                    label="Duration"
                    rules={[
                      {
                        required: true,
                        message: "Please select date",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select a date"
                      optionFilterProp="children"
                    >
                      <Select.Option key={0} value={0}>
                        {`1 Jan  to 30 Jun`}
                      </Select.Option>
                      <Select.Option key={1} value={1}>
                        {`1 Jul to 31 Dec`}
                      </Select.Option>
                    </Select>
                    {/* <DatePicker.RangePicker style={{ width: "100%" }} /> */}
                  </Form.Item>
                  <Form.Item
                    name="issuedDate"
                    label="Select Date of Generation"
                    rules={[
                      {
                        required: true,
                        message: "Please select the customer",
                      },
                    ]}
                  >
                    <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="place"
                    label="Place"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter place.",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="e.g. Pune"
                      type="text"
                      defaultValue="Pune"
                      disabled="true"
                    />
                  </Form.Item>
                  <Space>
                    <Form.Item
                      name="includeLetterheadHeader"
                      valuePropName="checked"
                      style={{ marginTop: "30px" }}
                    >
                      <Checkbox>Print With Letterhead</Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginTop: "30px" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={() => setReportData(undefined)}
                      >
                        {edit ? "Update" : "Create"}
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          )}

          {reportData && (
            <>
              <Space style={{ float: "right", marginBottom: "20px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setReportData(undefined);
                    if (view) {
                      navigate(-1);
                    }
                  }}
                  ghost
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownloadPDF({
                      pages: [<FormBPDF {...reportData} />],
                      fileName: `Form B_${reportData?.customer?.name}_${todayDate.format('DD-MM-YYYY')}`,
                    });
                  }}
                  ghost
                >
                  Download
                </Button>
                {userData?.employee?.accessType ===
                  `${"Super Admin" || "Admin"}` && (
                  <>
                    <Button type="primary" onClick={handleDelete}>
                      Delete
                    </Button>
                    {!edit && (
                      <Link to={`/edit/formB/${view ? params.id : reportId}`}>
                        <Button type="primary">Edit</Button>
                      </Link>
                    )}
                  </>
                )}
              </Space>
              <PDFViewer width={"100%"} height={1200}>
                <PDFDocument pages={[<FormBPDF {...reportData} />]} />
              </PDFViewer>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default FormB;
