import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Dropdown,
  Table,
  Modal,
  Radio,
  Upload,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Select } from "antd";
import { LuPlus } from "react-icons/lu";
import moment from "moment";
import axios from "axios";
const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ManageTask = ({ isEdit }) => {
  const [leadData, setLeadData] = useState();
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = useState(undefined);
  const [employeeValue, setEmployeeValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [attachmentFileId, setAttachmentFileId] = useState();
  const [systems, setSystems] = useState([]);
  const [employee, setEmployee] = useState(undefined);
  const [initialEmployee, setInitialEmployee] = useState();

  const getLeadDetails = async () => {
    try {
      const response = await APICall(`/task/${params.id}`, {}, 1);
      setLeadData(response.data);
      form.setFieldsValue({
        employeeId: {
          label: response.data?.employee?.user?.name,
          value: response.data?.employee?._id,
        },
        title: response.data.title,
        status: response.data.status,
        expectedCompletionDate: dayjs(new Date(response.data.expectedCompletionDate)),
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      message.error('Failed getting data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      getLeadDetails();
    }
  }, []);

  const onFinish = async (values) => {
    setIsLoading(!isLoading);
    if (isEdit) {
      const data = { ...values };
      data.employeeId = typeof(values.employeeId) === "string" ? values.employeeId : values.employeeId.value
      const response = await APICall(`/task/${params.id}`, data, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("Task has been updated successfully.");
        navigate("/task");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const data = { ...values, status: "Pending" };
        const response = await APICall(`/task`, data, 2);
        setIsLoading(false);
        if (response.data) {
          message.success("Task has been created successfully.");
          navigate(`/task`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const fetchEmployeeList = async (searchValue) => {
    const res = await APICall(
      `/employee?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((employee) => ({
      label: employee.user.name,
      value: employee._id,
    }));
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Card title={params.id ? 'Edit Task' : "Create Task"}>
            <Form
              form={form}
              layout="vertical"
              // wrapperCol={{
              //     span: 20,
              // }}
              onFinish={onFinish}
              initialValues={{ includeGST: true }}
            >
              <Row gutter={16}>
                <Col span={24}>
                    <Row gutter={10}>
                      <Col span={8}>
                        <Form.Item
                          name="title"
                          label="Title"
                          rules={[
                            {
                              required: true,
                              message: "Please enter title",
                            },
                          ]}
                        >
                          <Input placeholder="E.g. Meet with Mr. Ingale"/>
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name="employeeId"
                          label="Employee"
                          rules={[
                            {
                              required: true,
                              message: "Please select employee",
                            },
                          ]}
                        >
                          <DebounceSelect
                            value={employee}
                            placeholder="Select Employee"
                            fetchOptions={fetchEmployeeList}
                            defaultValue={initialEmployee}
                            onChange={(newValue) => {
                              setEmployee(newValue);
                            }}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                      {/* <Col span={8}>
                      <Form.Item
                          name="status"
                          label="Select status"
                          rules={[
                            {
                              required: true,
                              message: "Please select the status",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="e.g. Industrial"
                            // onChange={handleChange}
                            options={[
                              {
                                label: "Pending",
                                value: "Pending",
                              },
                              {
                                label: "In Progress",
                                value: "In Progress",
                              },
                              {
                                label: "Completed",
                                value: "Completed",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col> */}

                      <Col span={8}>
                        <Item
                          label="Expected Completion Date"
                          name="expectedCompletionDate"
                          rules={[
                            {
                              required: true,
                              message: "Please selete date",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} format={'DD-MM-YYYY'}/>
                        </Item>
                      </Col>

                      <Col span={24}>
                        <Item>
                          <Button type="primary" htmlType="submit" tabIndex={8}>
                            {isEdit ? "Save" : "Create"}
                          </Button>
                        </Item>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageTask;
