import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  message,
} from "antd";
import dayjs from "dayjs";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import APICall from "../../../services/apiservices";
const { Item } = Form;

const ManageLicenseCopy = ({ isEdit }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [customer, setCustomer] = useState(undefined);
  const [initialCustomer, setInitialCustomer] = useState();

  const getLicenseCopyDetails = async () => {
    try {
      const response = await APICall(`/licenseCopy/${params.id}`, {}, 1);
      form.setFieldsValue({
        customerId: {
          label: response.data.customer.user.name,
          value: response.data.customer._id,
        },
        licenseCopyNumber: response.data.licenseCopyNumber,
        date: dayjs(new Date(response.data.date)),
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      message.error('Failed getting data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      getLicenseCopyDetails();
    }
  }, []);

  const onFinish = async (values) => {
    setIsLoading(!isLoading);
    if (isEdit) {
      const data = { ...values };
      data.customerId = typeof(values.customerId) === "string" ? values.customerId : values.customerId.value
      const response = await APICall(`/licenseCopy/${params.id}`, data, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("License copy has been updated.");
        navigate("/licenseCopy");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const data = { ...values };
        const response = await APICall(`/licenseCopy`, data, 2);
        setIsLoading(false);
        if (response.data) {
          message.success("License copy has been created.");
          navigate(`/licenseCopy`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Card title={params.id ? 'Edit License Copy' : "Create License Copy"}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col span={24}>
                    <Row gutter={10}>
                      
                      <Col span={8}>
                        <Form.Item
                          name="customerId"
                          label="Customer"
                          rules={[
                            {
                              required: true,
                              message: "Please select customer",
                            },
                          ]}
                        >
                          <DebounceSelect
                            value={customer}
                            placeholder="Select Customer"
                            fetchOptions={fetchCustomerList}
                            defaultValue={initialCustomer}
                            onChange={(newValue) => {
                              setCustomer(newValue);
                            }}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                  

                      <Col span={24}>
                        <Item>
                          <Button type="primary" htmlType="submit" tabIndex={8}>
                            {isEdit ? "Save" : "Create"}
                          </Button>
                        </Item>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageLicenseCopy;
