import React, { useState, useEffect } from "react";
import { LuPlus } from "react-icons/lu";
import { Row, Col, Card, Button, Table, message, Space, Input } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import APICall from "../../services/apiservices";

const { Search } = Input;

const Quotations = () => {
  const [Quotations, setQuotations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getQuotations = async (searchTerm, page, limit) => {
    try {
      setIsLoading(true);
      const response = await APICall(
        `/quotation?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      setQuotations(response.data.documents);
      setTableParams((prevParams) => ({
        ...prevParams,
        pagination: {
          ...prevParams.pagination,
          total: response.data.total,
        },
      }));
    } catch (error) {
      message.error("Failed to fetch Quotation details");
    } finally {
      setIsLoading(false);
    }
  };

  // Initial Fetch on Component Mount
  useEffect(() => {
    getQuotations(
      tableParams.filters.searchTerm,
      tableParams.pagination.current,
      tableParams.pagination.pageSize
    );
  }, []); // Empty dependency array to ensure this runs only once on mount

  const handleTableChange = (pagination) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...pagination,
      },
    }));
    getQuotations(
      tableParams.filters.searchTerm,
      pagination.current,
      pagination.pageSize
    );
  };

  const onSearch = (value) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      filters: {
        ...prevParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...prevParams.pagination,
        current: 1, // Reset to first page on search
      },
    }));
    getQuotations(value, 1, tableParams.pagination.pageSize);
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Quotation #",
      key: "quotationNo",
      render: (_, data) => {
        return data.quotationNo;
      },
    },
    {
      title: "Customer Name",
      key: "name",
      render: (_, data) => {
        return data.user.name;
      },
    },
    {
      title: "Date",
      key: "date",
      render: (_, data) => {
        return moment(data.date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Amount (₹)",
      key: "amount",
      render: (_, data) => {
        return data.amount;
      },
    },
    {
      title: "Discount (₹)",
      key: "discount",
      render: (_, data) => {
        return `${data.discountAmount}`;
      },
    },
    {
      title: "CGST (₹)",
      key: "cgst",
      render: (_, data) => {
        return `${(data.isIGST === undefined || data.isIGST === false) ? data.cgstAmount : "-"}`;
      },
    },
    {
      title: "SGST (₹)",
      key: "cgst",
      render: (_, data) => {
        return `${(data.isIGST === undefined || data.isIGST === false) ? data.sgstAmount : "-"}`;
      },
    },
    {
      title: "IGST (₹)",
      key: "igst",
      render: (_, data) => {
        return `${(data.isIGST === undefined || data.isIGST === false) ? "-" : ((data.amount - data.discountAmount)* 0.18)}`;
      },
    },
    {
      title: "Total Amount (₹)",
      key: "totalAmount",
      render: (_, data) => {
        return data.totalAmount;
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_, data) => (
        <Space>
          <Link to={`/quotations/${data._id}`}>
            <Button type="primary" ghost>
              View
            </Button>
          </Link>
        </Space>
      ),
      align: "center",
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Quotations"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 370,
                  }}
                  placeholder="Enter Quotation Number or Customer Name"
                  allowClear
                  enterButton
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/quotations/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create Quotation
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={Quotations}
            onChange={handleTableChange}
            size="small"
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Quotations;
