import React, { useEffect, useState } from "react";
import {
  Card,
  Descriptions,
  Button,
  message,
  Table,
  Badge,
  Space,
  Modal,
} from "antd";
import { Link, useParams } from "react-router-dom";
import APICall from "../../../services/apiservices";
const { confirm } = Modal;
const CustomerDetails = () => {
  const params = useParams();
  const [customerData, setCustomerData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [contactPersons, setContactPersonse] = useState();
  const getCustomerDetails = async () => {
    try {
      const response = await APICall(`/customer/${params.customerId}`, {}, 1);
      console.log(response.data);
      setCustomerData(response.data);
      setContactPersonse(response.data.contactPersons);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getCustomerDetails();
  }, []);

  const handleActivate = () => {
    confirm({
      title: `${
        customerData?.user?.isActive ? "Disable" : "Enable"
      } customer account`,
      content: `Are you sure you want to ${
        customerData?.user?.isActive ? "disable" : "enable"
      } customer ${customerData?.user?.name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          let res;
          if (customerData?.user?.isActive) {
            res = await APICall(
              `/user/${customerData?.user?._id}/disable`,
              {},
              3
            );
          } else {
            res = await APICall(
              `/user/${customerData?.user?._id}/enable`,
              {},
              3
            );
          }
          // setReload(true);
          if (res.data) {
            message.success(
              `Customer  ${
                customerData?.user?.isActive ? "disabled" : "enabled"
              } successfully!`
            );
            getCustomerDetails();
          }
        } catch (error) {
          message.error(error.response.data.message);
        }
      },
    });
  };

  const handleResetPassword = () => {
    confirm({
      title: "Confirm Reset Password",
      content: " Are you sure you want to reset password?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await APICall(
            `/user/${customerData?.user?._id}/password`,
            {},
            3
          );
          if (res?.data?.data) {
            confirm({
              title: "Password Reset Successful",
              content: (
                <>
                  <Descriptions>
                    <Descriptions.Item label="Email">
                      {res?.data?.data?.email}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="Password">
                      {res?.data?.data?.password}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              ),
              okType: "danger",
              okText: "Ok",
              cancelButtonProps: {
                style: { display: "none" }, // Hide the cancel button
              },
            });
          }
        } catch (error) {
          message.error(error.response.data.message);
        }
      },
    });
  };
  const handleSendPassword = () => {
    confirm({
      title: "Confirm Send Password",
      content: " Are you sure you want to send password?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await APICall(
            `/user/${customerData?.user?._id}/password`,
            { sendEmail: true },
            3
          );
          message.success("Password sent successfully");
        } catch (error) {
          message.error(error);
        }
      },
    });
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      fixed: "left",
      render: (_, data) => {
        return data.name;
      },
      responsive: ["md"],
    },
    {
      title: "Mobile",
      key: "name",
      fixed: "left",
      render: (_, data) => {
        return data.mobile;
      },
      responsive: ["md"],
    },

    {
      title: "Email",
      key: "name",
      fixed: "left",
      render: (_, data) => {
        return data.email;
      },
      responsive: ["md"],
    },
    {
      title: "Designation",
      key: "name",
      fixed: "left",
      render: (_, data) => {
        return data.designation;
      },
      responsive: ["md"],
    },

    // {
    //   title: "Actions",
    //   key: "actions",
    //   fixed: "right",
    //   render: (_, data) => (
    //     <Dropdown
    //       menu={{
    //         items: [
    //           {
    //             key: 1,
    //             label: (
    //               <Link to={`/employees/attendance/${data._id}`}>Reset</Link>
    //             ),
    //           },

    //           {
    //             key: 2,
    //             label: (
    //               <span
    //                 style={{ color: "red" }}
    //                 onClick={() => handleDelete(data)}
    //               >
    //                 Delete
    //               </span>
    //             ),
    //           },
    //         ],
    //       }}
    //       placement="bottom"
    //     >
    //       <Button type="link">Actions</Button>
    //     </Dropdown>
    //   ),
    //   align: "center",
    // },
  ];

  return (
    <>
      <Card
        title="Customer Details"
        loading={isLoading}
        extra={
          <Space>
            {customerData?.user?.isActive && (
              <>
                <Button type="primary" onClick={handleResetPassword}>
                  Reset Password
                </Button>
                <Button type="primary" onClick={handleSendPassword}>
                  Send Password Via Email
                </Button>
                <Link to={`/customers/manage/${params.customerId}`}>
                  <Button type="primary" ghost>
                    Edit
                  </Button>
                </Link>
              </>
            )}

            <Button type="primary" onClick={handleActivate}>
              {customerData?.user?.isActive ? "Disable" : "Enable"}
            </Button>
          </Space>
        }
      >
        <Descriptions layout="horizontal" bordered>
          <Descriptions.Item label="Name">
            {customerData?.user?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {customerData?.user?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile">
            {customerData?.user?.mobile}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {customerData?.user?.address}
          </Descriptions.Item>
          <Descriptions.Item label="State">
            {customerData?.user?.state}
          </Descriptions.Item>
          <Descriptions.Item label="Pincode">
            {customerData?.user?.pincode}
          </Descriptions.Item>

          <Descriptions.Item label="GSTIN">
            {customerData?.gstNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Access Status">
            {customerData?.user?.isActive ? (
              <Badge status="success" text="Active" />
            ) : (
              <Badge status="warning" text="Inactive" />
            )}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <br />

      <Card title="Contact Persons" bordered={false} loading={isLoading}>
        <Table
          columns={columns}
          dataSource={contactPersons}
          scroll={{ x: true }}
          // pagination={true}
        />
      </Card>
    </>
    // <>
    //   {isLoading ? (
    //     <Row
    //       justify="center"
    //       align="middle"
    //       style={{ position: "absolute", top: "50%", left: "50%" }}
    //     >
    //       <Spin tip={"Loading"} />
    //     </Row>
    //   ) : (

    //   )}
    // </>
  );
};

export default CustomerDetails;
