import React from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { CiSettings } from "react-icons/ci";
import {
  Layout,
  Tag,
  Row,
  Avatar,
  Space,
  Typography,
  Dropdown,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import { useState } from "react";
const { Header } = Layout;
const { Text } = Typography;

const SiteHeader = () => {
  const { userData, setUserData } = useAuth();
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState();
  const showImage = (img) => {
    setImg(img);
    setVisible(true);
  };

  return (
    <Header
      style={{
        backgroundColor: "#181D23",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#fff",
        padding: "0px 22px",
        height: "60px",
      }}
    >
      <div className="demo-logo">
        <img src="/ffss_white.png" alt="logo" height={50} />
        {/* <img src="/ffsslogo.png" alt="logo" /> */}
        {/* <span style={{ fontWeight: "500" }}>Fire Fight Safety Solutions</span> */}
      </div>
      <div className="content">
        <Row justify={"center"} align={"middle"}>
          <Image
            preview={{
              visible,
              src: img,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
          <Space>
            <div className="access">
              <Tag color="orange">
                {userData?.employee?.accessType
                  ? userData?.employee?.accessType
                  : "Customer"}
              </Tag>
              <Avatar
                src={
                  userData?.photoURL
                    ? userData.photoURL
                    : "https://xsgames.co/randomusers/avatar.php?g=pixel&key=1"
                }
                size={35}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showImage(userData?.photoURL);
                }}
              />
            </div>
            <Dropdown
              trigger="click"
              menu={
                userData?.employee?.accessType === "Super Admin" || "Admin"
                  ? {
                      items: [
                        {
                          label: <Link to="/settings">Settings</Link>,
                          key: "profile",
                          icon: <CiSettings style={{ fontSize: "18px" }} />,
                        },
                        {
                          label: (
                            <Link style={{ color: "red" }} to={"/"}>
                              Log Out
                            </Link>
                          ),
                          key: "logout",
                          icon: (
                            <RiLogoutCircleLine
                              style={{ fontSize: "18px", color: "red" }}
                            />
                          ),
                          onClick: () => {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("userData");
                            setUserData(null);
                          },
                        },
                      ],
                    }
                  : {
                      items: [
                        {
                          label: (
                            <Link style={{ color: "red" }} to={"/"}>
                              Log Out
                            </Link>
                          ),
                          key: "logout",
                          icon: (
                            <RiLogoutCircleLine
                              style={{ fontSize: "18px", color: "red" }}
                            />
                          ),
                          onClick: () => {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("userData");
                            setUserData(null);
                          },
                        },
                      ],
                    }
              }
            >
              <div
                className="user-info"
                style={{
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <Text strong style={{ color: "#fff", padding: 0 }}>
                  {userData?.name}
                </Text>
                <BiChevronDown style={{ fontSize: "20px" }} />
              </div>
            </Dropdown>
          </Space>
        </Row>
      </div>
    </Header>
  );
};

export default SiteHeader;
