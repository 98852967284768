import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Card,
  Col,
  Table,
  Space,
  Button,
  message,
  Input,
  Tag,
} from "antd";
import APICall from "../../services/apiservices";
import { Link, useNavigate } from "react-router-dom";
import moment, { utc } from "moment";
import TaskDiary from "./taskDiary/TaskDiary";
import confirm from "antd/es/modal/confirm";
const { Search } = Input;

const Tasks = () => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const navigate = useNavigate();

  const fetchList = async () => {
    const searchTerm = tableParams.filters.searchTerm || "";
    const apiDate = moment(selectedDate).format("YYYY-MM-DD");
    const response = await getLeads(searchTerm, apiDate);

    // because tasks appear one day behind, shifting the keys by one day:
    let adjustedResponse = {};
    for (const [key, tasksForDay] of Object.entries(response)) {
      const correctedDate = moment(key, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
      adjustedResponse[correctedDate] = tasksForDay;
    }

    const key = moment(selectedDate).format("YYYY-MM-DD");
    setLeads(adjustedResponse[key] || []);

    setIsLoading(false);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), selectedDate]);

  const getLeads = async (searchTerm, date) => {
    const localDateString = moment(date, "YYYY-MM-DD").subtract(1, 'day').format("YYYY-MM-DD");
    try {
      const response = await APICall(
        `/task/getTasksOfSevenDaysFromDate?date=${localDateString}&query=${searchTerm}`,
        {},
        1
      );
      return response.data;
    } catch (error) {
      message.error("failed to get task data");
      setIsLoading(false);
    }
  };

  const changeStatus = async(taskid, status) => {
    try {
      const response = await APICall(`/task/${taskid}`, { status }, 3);
    } catch (error) {
      console.log("error updating task status");
    }

    await fetchList();
  }

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Date",
      dataIndex: "expectedCompletionDate",
      key: "expectedCompletionDate",
      render: (text, record) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Employee",
      dataIndex: ["employee", 'user', 'name'],
      key: "employeeId",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Tag color={_ === 'Pending' ? "yellow" : 
          new Date(record.expectedCompletionDate).setHours(0,0,0,0) === new Date().setHours(0,0,0,0) ? "green" : 
          new Date(record.expectedCompletionDate) > new Date() ? "blue" : "red"}>
          {_}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Link to={`/task/details/${record._id}`}>
            <Button type="primary" ghost>
              View
            </Button>
          </Link>
          <Button type="primary" ghost onClick={() => {
            
          }}>
              Mark Done
          </Button>
        </Space>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setLeads([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const handleDelete = async (taskid) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      cancelText: "No",
      okType: "danger",
      onOk: async () => {
        const res = await APICall(`/task/${taskid}`, {}, 4);
        message.success("Task deleted successfully!");
        window.location.reload();
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
    await fetchList();
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Task Management"
          extra={
            <Link to="/task/manage">
              <Button
                type="primary"
                icon={<LuPlus style={{ paddingTop: "2px" }} />}
              >
                Create Task
              </Button>
            </Link>
          }
        >
          {/* <Table
            dataSource={leads}
            columns={columns}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
            size="small"
          /> */}
          <TaskDiary 
            tasks={leads} 
            setTasks={setLeads}
            selectedDate={selectedDate} 
            setSelectedDate={setSelectedDate}
            changeStatus={changeStatus}
            handleDelete={handleDelete}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Tasks;
