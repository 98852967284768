import React, { useState, useEffect } from "react";
import { Button } from "antd";
import moment from "moment";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

const ScrollableDateHeader = ({ onDateSelect }) => {
  const [startDate, setStartDate] = useState(moment().clone().subtract(3, "days"));
  const [selectedDate, setSelectedDate] = useState(moment());

  const visibleDates = Array.from({ length: 7 }, (_, i) =>
    startDate.clone().add(i, "days")
  );

  const handlePrevDay = () => {
    const newSelectedDate = selectedDate.clone().subtract(1, "days");

    setSelectedDate(newSelectedDate);
    onDateSelect(newSelectedDate);

    if (newSelectedDate.isBefore(visibleDates[0], "day")) {
      setStartDate(startDate.clone().subtract(1, "days"));
    }
  };

  const handleNextDay = () => {
    const newSelectedDate = selectedDate.clone().add(1, "days");

    setSelectedDate(newSelectedDate);
    onDateSelect(newSelectedDate);

    if (newSelectedDate.isAfter(visibleDates[visibleDates.length - 1], "day")) {
      setStartDate(startDate.clone().add(1, "days"));
    }
  };

  const handlePrevWeek = () => {
    const newStartDate = startDate.clone().subtract(7, "days");
    setStartDate(newStartDate);
    setSelectedDate(newStartDate);
    onDateSelect(newStartDate);
  };

  const handleNextWeek = () => {
    const newStartDate = startDate.clone().add(7, "days");
    setStartDate(newStartDate);
    setSelectedDate(newStartDate);
    onDateSelect(newStartDate);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  useEffect(() => {
    const today = moment();
    const middleStartDate = today.clone().subtract(3, "days");
    setStartDate(middleStartDate);
    setSelectedDate(today);
    onDateSelect(today); // Notify parent
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        background: "#f9f9f9",
        borderBottom: "1px solid #ddd",
      }}
    >
      {/* Left Arrow */}
      <Button
        icon={<DoubleLeftOutlined />}
        onClick={handlePrevWeek}
        style={{ marginRight: "10px" }}
      />

      <Button
        icon={<LeftOutlined />}
        onClick={handlePrevDay}
        style={{ marginRight: "10px" }}
      />

      {/* Dates */}
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          flex: "1 1 auto",
          justifyContent: "space-between",
        }}
      >
        {visibleDates.map((date) => (
          <Button
            key={date.format("YYYY-MM-DD")}
            type={selectedDate.isSame(date, "day") ? "primary" : "default"}
            style={{
              margin: "0 5px",
              flex: "0 0 auto",
              textAlign: "center",
              minWidth: "80px",
            }}
            onClick={() => handleDateClick(date)}
          >
            <div>{date.format("ddd, DD MMM")}</div>
          </Button>
        ))}
      </div>

      <Button
        icon={<RightOutlined />}
        onClick={handleNextDay}
        style={{ marginLeft: "10px" }}
      />

      {/* Right Arrow */}
      <Button
        icon={<DoubleRightOutlined />}
        onClick={handleNextWeek}
        style={{ marginLeft: "10px" }}
      />
    </div>
  );
};

export default ScrollableDateHeader;
